import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerAdjustmentService } from './cust-adjustment.service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RoleBasedAccessService } from '../role-based-access.service';
import { CUSTOMER_REFUND_ACCESS, CUSTOMER_DEBIT_ACCESS, CUSTOMER } from '../roles.constants';
import { AppConfigService } from '../appConfig.service';

const ADJUSTMENT_TYPES = {
  REFUND : 'Refund',
  DEBIT : 'Debit',
  CREDIT: 'Credit'
}

@Component({
  selector: 'app-cust-adjustment',
  templateUrl: './cust-adjustment.component.html',
  styleUrls: ['./cust-adjustment.component.css'],
})
export class CustAdjustmentComponent implements OnInit, OnChanges {

  orderId: any;
  accessRoles: any;
  roles =  this.accessCookie('Roles').split(',');
  unavailableStatuses = ['new', 'onTheWay', 'arrived', 'started'];
  statusValue = [];
  tickets = [];
  logData = {};
  public coinsCreditReasons = [];
  public debitCustReasons = [];
  public refundCustReasons = [];
  public disableSubmit: any;
  @Input() customerAdjDetails: any;
  @Input() orderStatus: any;
  @Input() walletInfo: any;
  @Input() coinPreference: any;
  @Output() adjustmentDone: EventEmitter<any> = new EventEmitter<any>();

  wallets = {
    rapido : "Rapido Wallet",
    prevDue: "Previous Due",
    lazypay: "Lazypay",
    freecharge: "Freecharge",
    simpl: "Simpl",
    gpay: "Gpay/JusPay",
    paytm: "Paytm",
    amazonpay: "Amazon Pay",
    coins: "Coins",
    upi : "Upi"
  }

  walletOptions = [
    this.wallets.rapido,
    this.wallets.prevDue,
    this.wallets.paytm,
    this.wallets.freecharge,
    this.wallets.lazypay,
    this.wallets.simpl,
    this.wallets.gpay,
    this.wallets.amazonpay,
    this.wallets.upi,
  ]

  debitWalletOptions = [
    this.wallets.rapido,
    this.wallets.prevDue
  ]

  creditWalletOptions = [
    this.wallets.coins
  ]

  public info: any = {
    header: 'Customer Adjustment',
    headerClass: null,
    dataFetched: true,
    rows: []
  };
  public success = false;
  constructor(private route: ActivatedRoute,
              private customerAdjService: CustomerAdjustmentService,
              private toasterService: ToasterService,
              public dialog: MatDialog,
              public appConfigService: AppConfigService,
              public roleBasedAccess: RoleBasedAccessService) { }
  async ngOnInit() {
    this.orderId = this.route.snapshot.params.id;
    this.accessRoles = this.accessCookie('accessRoles');
    const adjustmentConfig : any = await this.appConfigService.getAdjustmentConfig();
    this.coinsCreditReasons = JSON.parse(adjustmentConfig.creditReasonsOrder);
    this.debitCustReasons = JSON.parse(adjustmentConfig.debitReasonsOrder);
    this.refundCustReasons = JSON.parse(adjustmentConfig.refundReasonsOrder);
    this.checkStatus();
  }

  checkStatus() {
    // Push Refund if logged in user has Access for it.
    const refundAccess = Object.keys(CUSTOMER_REFUND_ACCESS);
    if ((this.roles.some(redundRole => refundAccess.indexOf(redundRole) >= 0)) && this.statusValue.indexOf('Refund') < 0) {
      this.statusValue.push('Refund');
    }

    // Push Debit if logged in user has Access for it.
    const debitAccess = Object.keys(CUSTOMER_DEBIT_ACCESS);
    if ((this.roles.some(debitRole => debitAccess.indexOf(debitRole) >= 0)) && this.statusValue.indexOf('Debit') < 0) {
      this.statusValue.push('Debit');
    }

    if(this.statusValue.indexOf('Credit') < 0) {
      this.statusValue.push('Credit');
    }
    this.constructData();
  }

  constructData() {
    if (this.statusValue.length > 0) {
      this.info['rows'] = [
        [
          {titleClass: 'form-title-class', title: 'Action', type: 'single-searchable-dropdown', values: this.statusValue,
            placeholder: 'Select status', key: 'cust-adj-action', default: undefined},
          {titleClass: 'form-title-class', title: 'Ticket ID', type: 'text-input', placeholder: 'Ticket ID', key: 'cust-adj-ticket'},
          {titleClass: 'form-title-class', title: 'Reason', type: 'single-searchable-dropdown', values: [],
            placeholder: 'Select reason', key: 'cust-adj-reason', className: 'col-md-6'}
        ],
        [
          {titleClass: 'form-title-class', title: 'Amount', type: 'text-input', placeholder: 'Enter Amount', key: 'cust-adj-amount'},
          {titleClass: 'form-title-class', title: 'Wallet', type: 'single-searchable-dropdown', values: this.walletOptions,
            placeholder: 'Select wallet', key: 'cust-adj-wallet', default: undefined}
        ]
      ];
    } else {
      this.info = {
        header: 'Customer Adjustment',
        accessDenied: true,
        dataFetched: true
      };
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.customerAdjDetails && changes.customerAdjDetails.currentValue ) {
      if (changes.customerAdjDetails.currentValue.deliveryOrder) {
        this.emptyCard('Not available for Delivery orders');
      } else if (changes.customerAdjDetails.currentValue.status &&
        this.unavailableStatuses.indexOf(changes.customerAdjDetails.currentValue.status) !== -1) {
          this.emptyCard('Not available for the current order status');
      } else if (!changes.customerAdjDetails.currentValue.dataFetched) {
        this.emptyCard('Cannot load adjustment');
      }
    }
  }

  checkAccess(role) {
    if (this.accessRoles && this.accessRoles.indexOf(role) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  emptyCard(message?) {
    this.info = {
      header: 'Customer Adjustment',
      dataFetched: false,
      emptyMessage: message || 'No data available'
    };
  }

  changeRows(event) {
    if (event === 'Others') {
      this.info['rows'][1][2] = {titleClass: 'form-title-class', title: 'Other Reason', type: 'text-input',
                                  placeholder: 'Provide an explanation', key: 'cust-adj-other-reason'};
    } else if (event === ADJUSTMENT_TYPES.REFUND) {
      this.info['rows'] = [
        [
          {titleClass: 'form-title-class', title: 'Action', type: 'single-searchable-dropdown', values: this.statusValue,
            placeholder: 'Select status', key: 'cust-adj-action', default: event},
          {titleClass: 'form-title-class', title: 'Ticket ID', type: 'text-input', placeholder: 'Ticket ID', key: 'cust-adj-ticket'},
          {titleClass: 'form-title-class', title: 'Reason', type: 'single-searchable-dropdown',
            values: this.refundCustReasons,
              placeholder: 'Select reason', key: 'cust-adj-reason', className: 'col-md-6'}
        ],
        [
          {titleClass: 'form-title-class', title: 'Amount', type: 'text-input', placeholder: 'Enter Amount', key: 'cust-adj-amount'},
          {titleClass: 'form-title-class', title: 'Wallet', type: 'single-searchable-dropdown', values: this.walletOptions,
            placeholder: 'Select wallet', key: 'cust-adj-wallet', default: undefined}
        ]
      ];
    } else if (event === ADJUSTMENT_TYPES.DEBIT) {
      this.info['rows'] = [
        [
        
          {titleClass: 'form-title-class', title: 'Action', type: 'single-searchable-dropdown', values: this.statusValue,
            placeholder: 'Select status', key: 'cust-adj-action', default: event},
            {titleClass: 'form-title-class', title: 'Ticket ID', type: 'text-input', placeholder: 'Ticket ID', key: 'cust-adj-ticket'},
          {titleClass: 'form-title-class', title: 'Reason', type: 'single-searchable-dropdown',
                  values: this.debitCustReasons,
                  placeholder: 'Select reason', key: 'cust-adj-reason', className: 'col-md-6'}
        ],
        [
          {titleClass: 'form-title-class', title: 'Amount', type: 'text-input', placeholder: 'Enter Amount', key: 'cust-adj-amount'},
          {titleClass: 'form-title-class', title: 'Wallet', type: 'single-searchable-dropdown', values: this.debitWalletOptions,
            placeholder: 'Select wallet', key: 'cust-adj-wallet', default: undefined}
        ]
      ];
    } 

    // coins credit
    else if (event === ADJUSTMENT_TYPES.CREDIT) {
      this.info['rows'] = [
        [
          {titleClass: 'form-title-class', title: 'Action', type: 'single-searchable-dropdown', values: this.statusValue,
            placeholder: 'Select status', key: 'cust-adj-action', default: event},
          {titleClass: 'form-title-class', title: 'Ticket ID', type: 'text-input', placeholder: 'Ticket ID', key: 'cust-adj-ticket'},
          {titleClass: 'form-title-class', title: 'Reason', type: 'single-searchable-dropdown',
            values: this.coinsCreditReasons, placeholder: 'Select reason', key: 'cust-adj-reason', className: 'col-md-6'}
        ],
        [
          {titleClass: 'form-title-class', title: 'Amount', type: 'text-input', placeholder: 'Enter Amount', key: 'cust-adj-amount'},
          {titleClass: 'form-title-class', title: 'Wallet', type: 'single-searchable-dropdown', values: this.creditWalletOptions,
            placeholder: 'Select wallet', key: 'cust-adj-wallet',}
        ]
      ];
    }
    else {
      for (let index = 0; index < this.info['rows'][1].length; index++) {
        if (this.info['rows'][1][index] && this.info['rows'][1][index].title === 'Other Reason') {
          this.info['rows'][1].splice(index, 1);
        }
      }
    }
  }

  async peformAdjustment(event) {
    let  userDetails = this.accessCookie('user');
    userDetails = JSON.parse(userDetails);
    let confirmDialogRef;
    const previousDue = this.walletInfo.outstandingBalance;
    const rapidoWallet = this.walletInfo.response.find(wallet => wallet.type === "rapido");
    const walletAmount = this.coinPreference ? rapidoWallet.balance : rapidoWallet.rapidoWalletBalance; 
    let reason: any;
    const orderAmount = this.customerAdjDetails.orderAmount;

    //Validation
    if (!event['cust-adj-action'] || !event['cust-adj-reason'] || !event['cust-adj-wallet'] || !event['cust-adj-ticket']) {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Please check customer adjustment details`,
      }));
      return;
    }

    let walletType = Object.keys(this.wallets).find(key => this.wallets[key] === event['cust-adj-wallet'])
    const adjustmentType = event['cust-adj-action'];
    if (!event['cust-adj-amount'] || event['cust-adj-amount'] < 0) {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: adjustmentType + ` amount should be greater than zero`,
      }));
      return;
    }else if(adjustmentType === ADJUSTMENT_TYPES.REFUND && event['cust-adj-wallet'] === this.wallets.prevDue){
      if(event['cust-adj-amount'] > previousDue){
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: adjustmentType +  ` amount should not be more than Previous Due`,
        }));
        return;
      }
    }else if(adjustmentType === ADJUSTMENT_TYPES.DEBIT && event['cust-adj-wallet'] === this.wallets.rapido ){
      if(event['cust-adj-amount'] > walletAmount){
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: adjustmentType +  ` amount should not be more than Rapido Wallet Amount`,
        }));
        return;
      }
    }else if(event['cust-adj-amount'] > orderAmount){
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: adjustmentType +  ` amount should not be more than Order Amount`,
      }));
      return;
    }
    if (event['cust-adj-reason'] === 'Others' && !event['cust-adj-other-reason']) {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Please enter reason`,
      }));
      return;
    }else if(event['cust-adj-reason'] === 'Others' && event['cust-adj-other-reason']){
      reason = event['cust-adj-other-reason'];
    }
    else {
      reason = event['cust-adj-reason'];
    }

    // call role based access api
    const maxAdjAmount = await this.roleBasedAccess.performAdjustment(adjustmentType, CUSTOMER);
    if (maxAdjAmount && event['cust-adj-amount'] && (parseInt(event['cust-adj-amount'], 10) > maxAdjAmount)) {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: 'You are allowed to  ' + adjustmentType + ' max Rs. ' + maxAdjAmount + '. Please enter less than ' + maxAdjAmount
      }));
      return;
    }

    // Refund/Debit Obj
    const customer = this.customerAdjDetails.customer || {};
    const adjustmentObj = {
      actorId: userDetails['_id'],
      actorEmail: userDetails['emailId'],
      ownerId: this.customerAdjDetails.customerId || '',
      ownerType: 'customer',
      source: walletType || 'rapido',
      remarks: reason,
      wallet: walletType,
      email: customer.email || '',
      uniqueId:  this.customerAdjDetails.uniqueId || '',
      ownerName: customer.name || '',
      ownerPhoneNumber: customer.mobile || '',
      orderId: this.orderId,
      ticketId: event['cust-adj-ticket']
    };

    const customerCreatedOn = this.objectIdToEpochTimestamp(this.customerAdjDetails.customerId)

    confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '335px',
      data: { title: 'Are you sure you want to  ' + event['cust-adj-action'] + ' ?',
              type: 'order-details',
              reason: event['cust-adj-action'],
              message: '',
              buttonText: 'Yes, ' + event['cust-adj-action']
            }
    });

    // refund or credit
    if (event['cust-adj-action'] === ADJUSTMENT_TYPES.REFUND || event['cust-adj-action'] === ADJUSTMENT_TYPES.CREDIT) {
      adjustmentObj['custRefundAmount'] = event['cust-adj-amount'];      confirmDialogRef.afterClosed().subscribe(confirmResult => {
        if (confirmResult) {
          this.disableSubmit = true;
          if (event['cust-adj-action'] === ADJUSTMENT_TYPES.REFUND) {
            this.logData = {
              type: 'CUSTOMER_ADJUSTMENT_ACTION_REFUND',
              agent: {
                agentId: userDetails['_id'],
                email: userDetails['emailId'],
                roles: this.roles,
              },
              user: {
                userId: this.customerAdjDetails.customerId || '',
                createdOn: customerCreatedOn
              },
              source: 'profiles-dashboard',
              metadata: adjustmentObj,
              eventTimeStamp: new Date().getTime(), 
            }
          } else if (event['cust-adj-action'] === ADJUSTMENT_TYPES.CREDIT) {
            this.logData = {
              type: 'CUSTOMER_ADJUSTMENT_ACTION_CREDIT',
              agent: {
                agentId: userDetails['_id'],
                email: userDetails['emailId'],
                roles: this.roles,
              },
              user: {
                userId: this.customerAdjDetails.customerId || '',
                createdOn: customerCreatedOn
              },
              source: 'profiles-dashboard',
              metadata: adjustmentObj,
              eventTimeStamp: new Date().getTime(), 
            }
          }
          this.customerAdjService.customerRefundDetails(adjustmentObj, userDetails['_id'], this.logData).subscribe(response => {
            if (response && response['info'] && response['info'].status === 'success') {
              this.toasterService.showToaster(new Toaster({
                type: ToasterType.SUCCESS,
                message: `Amount refunded successfully`
              }));
              this.success = true;
              this.disableSubmit = false;
              this.checkStatus();
              this.adjustmentDone.emit();
            }
          }, err => {
            this.disableSubmit = false;
            this.toasterService.showToaster(new Toaster({
              type: ToasterType.WARNING,
              message: `Unable to refund for customer`,
            }));
          });
          
        }
      });
    } else {
      adjustmentObj['customerPayableAmount'] = event['cust-adj-amount'];
      confirmDialogRef.afterClosed().subscribe(confirmResult => {
        if (confirmResult) {
          this.disableSubmit = true;
          this.logData = {
            type: 'CUSTOMER_ADJUSTMENT_ACTION_DEBIT',
            agent: {
              agentId: userDetails['_id'],
              email: userDetails['emailId'],
              roles: this.roles,
            },
            user: {
              userId: this.customerAdjDetails.customerId || '',
              createdOn: customerCreatedOn
            },
            source: 'profiles-dashboard',
            metadata: adjustmentObj,
            eventTimeStamp: new Date().getTime(), 
          }
          this.customerAdjService.customerDebitDetails(adjustmentObj, userDetails['_id'], this.logData).subscribe(response => {
            if (response && response['info'] && response['info'].status === 'success') {
              this.toasterService.showToaster(new Toaster({
                type: ToasterType.SUCCESS,
                message: `Amount debited successfully`
              }));
              this.success = true;
              this.disableSubmit = false;
              this.checkStatus();
              this.adjustmentDone.emit();
            }
          }, err => {
            if (err && err['error'] && err['error'].data) {
              this.disableSubmit = false;
              const errorMessage = JSON.parse(err.error.data.split('-')[1]);
              this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: errorMessage.message,
              }));
            }
          });
        }
      });
    }
  }

  accessCookie(cookieName) {
    const name = cookieName + '=';
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
      if (allCookieArray[i]) {
        const cookieItem = allCookieArray[i].trim();
        if (cookieItem.indexOf(name) === 0) {
          return cookieItem.substring(name.length, cookieItem.length);
        }
      }
    }
    return '';
  }

  objectIdToEpochTimestamp(objectId) {
    if (!objectId) {
        return 0;
    }
    const timestamp = parseInt(objectId.substring(0, 8), 16);
    const epochTimestamp = timestamp * 1000;
    return epochTimestamp;
  }
}
