import { MyHttpClient } from '../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../http-client.service";
var CustomerAdjustmentService = /** @class */ (function () {
    function CustomerAdjustmentService(http) {
        this.http = http;
        this.paymentUrl = environment.server + '/api/payments';
        this.fetchTicketsUrl = environment.server + '/api/tickets';
    }
    CustomerAdjustmentService.prototype.customerRefundDetails = function (refundObj, userId, logData) {
        var requestBody = {
            refundObj: refundObj,
            userId: userId,
            logData: logData
        };
        return this.http.post(this.paymentUrl + '/refund', requestBody).pipe(catchError(this.handleError));
    };
    CustomerAdjustmentService.prototype.customerDebitDetails = function (debitObject, userId, logData) {
        var requestBody = {
            debitObject: debitObject,
            userId: userId,
            logData: logData
        };
        return this.http.post(this.paymentUrl + '/debit', requestBody).pipe(catchError(this.handleError));
    };
    CustomerAdjustmentService.prototype.CustomerTickets = function (userId) {
        return this.http.get(this.fetchTicketsUrl + '/' + userId).pipe(catchError(this.handleError));
    };
    CustomerAdjustmentService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    CustomerAdjustmentService.ngInjectableDef = i0.defineInjectable({ factory: function CustomerAdjustmentService_Factory() { return new CustomerAdjustmentService(i0.inject(i1.MyHttpClient)); }, token: CustomerAdjustmentService, providedIn: "root" });
    return CustomerAdjustmentService;
}());
export { CustomerAdjustmentService };
