import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faFilter, faRedo } from '@fortawesome/free-solid-svg-icons';
import { CaptainLastLocationsService } from 'src/app/captain-last-locations.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { ToasterService } from 'src/app/toaster.service';
var CaptainLastLocationsComponent = /** @class */ (function () {
    function CaptainLastLocationsComponent(toasterService, captainLastLocationService) {
        this.toasterService = toasterService;
        this.captainLastLocationService = captainLastLocationService;
        this.faRefresh = faRedo;
        this.faFilter = faFilter;
        this.locationPings = [];
        this.locationPingMarkers = [];
        this.locationPingIcon = {
            url: '../../assets/map/actualRoute.png',
            scaledSize: new google.maps.Size(10, 10)
        };
        this.timestampPattern = '^([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$';
        this.defaultDate = new Date(Date.now());
        this.locationPingInfoWindow = new google.maps.InfoWindow({});
        this.errorReasons = {
            DIFF_GREATER_THAN_HOUR: 'Timestamps difference exceeding 15 minutes is not allowed.',
            NO_LOCATION_PINGS: 'No location pings were found for the specified time range.',
            LOCATION_PINGS_FETCH_FAILED: 'Location pings fetch failed'
        };
    }
    CaptainLastLocationsComponent.prototype.padZero = function (value) {
        return value < 10 ? "0" + value : value.toString();
    };
    CaptainLastLocationsComponent.prototype.formatTime = function (date) {
        var hours = this.padZero(date.getHours());
        var minutes = this.padZero(date.getMinutes());
        var seconds = this.padZero(date.getSeconds());
        return hours + ":" + minutes + ":" + seconds;
    };
    ;
    CaptainLastLocationsComponent.prototype.getDefaultTimestamps = function () {
        var currentDate = new Date();
        var startTime = new Date(currentDate.getTime() - 15 * 59000);
        var defaultStartTime = this.formatTime(startTime);
        var defaultEndTime = this.formatTime(currentDate);
        return {
            defaultStartTime: defaultStartTime, defaultEndTime: defaultEndTime
        };
    };
    CaptainLastLocationsComponent.prototype.initializeForm = function () {
        var _a = this.getDefaultTimestamps(), defaultEndTime = _a.defaultEndTime, defaultStartTime = _a.defaultStartTime;
        this.timeInputFormGroup = new FormGroup({
            date: new FormControl(this.defaultDate, null),
            startTime: new FormControl(defaultStartTime, [Validators.required, Validators.pattern(this.timestampPattern)]),
            endTime: new FormControl(defaultEndTime, [Validators.required, Validators.pattern(this.timestampPattern)])
        });
    };
    CaptainLastLocationsComponent.prototype.initializeMap = function () {
        this.mapProperties = {
            center: new google.maps.LatLng(13.00035107483828, 77.58797505841818),
            zoom: 15,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            clickableIcons: false,
            gestureHandling: 'cooperative'
        };
        this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapProperties);
    };
    CaptainLastLocationsComponent.prototype.ngOnInit = function () {
        this.initializeMap();
        this.initializeForm();
    };
    CaptainLastLocationsComponent.prototype.validateTimestamps = function (startTime, endTime) {
        return Math.abs(endTime - startTime) < 900000;
    };
    CaptainLastLocationsComponent.prototype.constructTimestamps = function () {
        var date = this.timeInputFormGroup.get("date").value;
        var enteredDate = new Date(date);
        var _a = this.timeInputFormGroup.get("startTime").value.split(':'), startHour = _a[0], startMinutes = _a[1], startSeconds = _a[2];
        var _b = this.timeInputFormGroup.get("endTime").value.split(':'), endHour = _b[0], endMinutes = _b[1], endSeconds = _b[2];
        var startTime = new Date(enteredDate.getFullYear(), enteredDate.getMonth(), enteredDate.getDate(), startHour, startMinutes, startSeconds).getTime();
        var endTime = new Date(enteredDate.getFullYear(), enteredDate.getMonth(), enteredDate.getDate(), endHour, endMinutes, endSeconds).getTime();
        return {
            startTime: startTime, endTime: endTime
        };
    };
    CaptainLastLocationsComponent.prototype.renderLocationMarkers = function (locationPings) {
        var _this = this;
        var _loop_1 = function (locationPing) {
            var locationPingMarker = new google.maps.Marker({
                icon: this_1.locationPingIcon
            });
            locationPingMarker.setPosition(locationPing.location);
            locationPingMarker.setMap(this_1.map);
            var timeStampContent = "\n      <div style=\"font-family: Arial; font-size: 14px;\">\n      <strong>Date:</strong> " + locationPing.date.toDateString() + "<br>\n      <strong>Time:</strong> " + locationPing.date.toLocaleTimeString() + "\n      </div>\n    ";
            locationPingMarker.addListener('click', function (event) {
                _this.locationPingInfoWindow.setContent(timeStampContent);
                _this.locationPingInfoWindow.setPosition(locationPing.location);
                _this.locationPingInfoWindow.open(_this.map);
            });
            this_1.locationPingMarkers.push(locationPingMarker);
        };
        var this_1 = this;
        for (var _i = 0, locationPings_1 = locationPings; _i < locationPings_1.length; _i++) {
            var locationPing = locationPings_1[_i];
            _loop_1(locationPing);
        }
        this.map.setCenter(locationPings[0].location);
    };
    CaptainLastLocationsComponent.prototype.removeLocationMarkers = function () {
        for (var _i = 0, _a = this.locationPingMarkers; _i < _a.length; _i++) {
            var locationPingMarker = _a[_i];
            locationPingMarker.setMap(null);
        }
        this.locationPingMarkers = [];
    };
    CaptainLastLocationsComponent.prototype.resetMap = function () {
        this.removeLocationMarkers();
        this.map.setCenter(new google.maps.LatLng(13.00035107483828, 77.58797505841818));
        this.map.setZoom(15);
    };
    CaptainLastLocationsComponent.prototype.reset = function () {
        this.resetMap();
        var _a = this.getDefaultTimestamps(), defaultEndTime = _a.defaultEndTime, defaultStartTime = _a.defaultStartTime;
        this.timeInputFormGroup.reset({
            date: new Date(),
            startTime: defaultStartTime,
            endTime: defaultEndTime
        });
        this.locationPings = [];
        this.locationPingMarkers = [];
    };
    CaptainLastLocationsComponent.prototype.showToaster = function (message) {
        this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: message
        }));
    };
    CaptainLastLocationsComponent.prototype.onSubmit = function () {
        var _this = this;
        this.removeLocationMarkers();
        var _a = this.constructTimestamps(), startTime = _a.startTime, endTime = _a.endTime;
        if (!this.validateTimestamps(startTime, endTime)) {
            this.showToaster(this.errorReasons.DIFF_GREATER_THAN_HOUR);
            return;
        }
        this.captainLastLocationService.
            getCaptainPreviousLocationsByTimestamps(this.captainId, startTime, endTime).subscribe(function (result) {
            _this.locationPings = _this.captainLastLocationService.constructLocationPings(result['locations']);
            if (_this.locationPings.length == 0) {
                _this.showToaster(_this.errorReasons.NO_LOCATION_PINGS);
            }
            else {
                _this.renderLocationMarkers(_this.locationPings);
            }
        }, function (error) {
            console.error("Captain last location fetch failed", error);
            _this.showToaster(_this.errorReasons.LOCATION_PINGS_FETCH_FAILED);
            return;
        });
    };
    return CaptainLastLocationsComponent;
}());
export { CaptainLastLocationsComponent };
