import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MyHttpClient } from '../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CustomerAdjustmentService  {
    paymentUrl = environment.server + '/api/payments';
    fetchTicketsUrl = environment.server + '/api/tickets';

    customerRefundDetails(refundObj, userId, logData) {
        const requestBody = {
            refundObj: refundObj,
            userId: userId,
            logData: logData
        };
        return this.http.post(this.paymentUrl + '/refund', requestBody).pipe(catchError(this.handleError));
    }

    customerDebitDetails(debitObject, userId, logData) {
        const requestBody = {
            debitObject: debitObject,
            userId: userId,
            logData: logData
        };
        return this.http.post(this.paymentUrl + '/debit', requestBody).pipe(catchError(this.handleError));
    }

    CustomerTickets(userId){
      return this.http.get(this.fetchTicketsUrl + '/' + userId).pipe(catchError(this.handleError));
    }


    private handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      // return an observable with a user-facing error message
      return throwError(error);
    }
    constructor(private http: MyHttpClient) { }
  }
