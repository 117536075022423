import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  Table,
  Pagination,
  TableData,
  TableDataTypes,
} from 'src/app/shared/types';
import { MatDialog } from '@angular/material/dialog';
import { OrderService } from '../../order-service.service';
import { Router } from '@angular/router';
import { HelperService } from '../../helper.service';
import { promise } from 'protractor';
import {
  FilterService, DateFilterInput, QuickFilters
} from 'src/app/filter.service';

const currentDate = new Date();
const startDate = new Date();
startDate.setDate(startDate.getDate() - 1);
HelperService.resetTimeTo(currentDate);
HelperService.resetTimeTo(startDate);
export const DEFAULT_DATE = {
  FROM_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 31)),
  ONE_DAY_FROM_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 1)),
  CURRENT_FROM_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate())),
  EARNINGS_FROM_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 7)),
  TO_DATE: currentDate
};

function defaultFiters() {
  let startDuration = HelperService.getDateOnlyV1(new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 31)));
  let endDuration = HelperService.getDateOnlyV1(currentDate);
  return {
    startDuration,
    endDuration,
  };
}
@Component({
  selector: 'app-captain-propagation-events',
  templateUrl: './captain-propagation-events.component.html',
  styleUrls: ['./captain-propagation-events.component.css'],
})
export class CaptainPropagationEventsComponent implements OnInit, OnChanges {
  @Input() captainObjectId: string;
  @Input() captainDetailsToggleableHeaders: string[];
  @Output() toggleHeaders: EventEmitter<any> = new EventEmitter();
  propagationEvents: any[];
  propagationEventsTable: Table;
  fromDate = DEFAULT_DATE.FROM_DATE;
  toDate = DEFAULT_DATE.TO_DATE;
  filtersApplied = defaultFiters();

  constructor(
    public dialog: MatDialog,
    public orderService: OrderService,
    public filterService: FilterService,
    private router: Router
  ) { }

  ngOnInit() {
    this.definePropagationEventsTable();
  }

  ngOnChanges(changes: SimpleChanges) { }

  private orderDateFormat(date: Date) {
    function padStart(length, data) {
      return data.toString().padStart(length, 0)
    }
    const dateString = `${date.getFullYear()}-${padStart(2, date.getMonth() + 1)}-${padStart(2, date.getDate())}`;
    return dateString;
  }

  private declareTable(pageNumber = 0) {
    this.propagationEventsTable = new Table({
      tableHeader: {
        toString: () => {
          return `Propagtion Events`;
        },
      },
      defaultError: 'No propagtion records found',
      headers: {
        date: 'Order Date',
        updatedAt: 'Updated At',
        orderId: 'OrderId',
        eventName: 'Event',
        serviceDetailId: 'ServiceDetailId',
        captainLocation: 'Captain Location',
        captainPositionInMappedList: 'Captain position in mapped list' + '\n' + '( position / total captains )'
      },
      selectedHeader: 10,
      toggleableHeaders: this.captainDetailsToggleableHeaders,
      pagination: new Pagination(pageNumber, 10),
      config: {
        refresh: true,
      },
      onRefresh: () => {
        this.propagationEventsTable = null;
        this.definePropagationEventsTable();
      },
      filterComponents: [
        new QuickFilters({
          key: 'duration',
          default: '',
          title: 'Duration',
        }),
      ],
      appliedFilters: this.filtersApplied,
      onFilter: () => {
        const timeSlot = this.filterService.appliedFilters.duration || ['', ''];
        let duration = [];
        if (!Array.isArray(timeSlot)) {
          duration.push(timeSlot['start']);
          duration.push(timeSlot['end']);
        } else {
          duration = timeSlot;
        }
        let startDuration = duration[0];
        let endDuration = duration[1];
        const filtersApplied = {
          duration: {
            start: startDuration,
            end: endDuration,
          },
          startDuration,
          endDuration,
        };
        Object.assign(this.filtersApplied, filtersApplied);
        const isValidNumber = (value) => {
          return ((parseInt(value, 10) === 0) || value);
        };
        if (startDuration && endDuration) {
          startDuration = HelperService.isValidDate(new Date(startDuration)) ? new Date(startDuration) : undefined;
          endDuration = HelperService.isValidDate(new Date(endDuration)) ? new Date(endDuration) : undefined;
          return this.definePropagationEventsTable(startDuration, endDuration);
        }
        return this.definePropagationEventsTable();
      },
      data: [],
    });
  }

  onPaginationChange(event) {
    this.propagationEventsTable.data = [];
    this.fillDataToTable(this.fromDate ? this.fromDate : DEFAULT_DATE.FROM_DATE, this.toDate ? this.toDate : DEFAULT_DATE.TO_DATE, this.filtersApplied);
  }

  changeInHeader(header) {
    this.toggleHeaders.emit(header);
  }

  definePropagationEventsTable(fromDate = DEFAULT_DATE.FROM_DATE, toDate = DEFAULT_DATE.TO_DATE) {
    this.declareTable();
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.fillDataToTable(fromDate, toDate, this.filtersApplied);
  }

  private getPropagationEvents(fromDate: Date, toDate: Date) {
    let startDate = this.orderDateFormat(fromDate);
    let endDate = this.orderDateFormat(toDate);
    return this.orderService.getPropagationEventsForCaptain(this.captainObjectId, this.propagationEventsTable.pagination, startDate, endDate)
  }

  fillDataToTable(fromDate?: Date, toDate?: Date, filters?: any) {
    this.propagationEventsTable.dataWillLoad();
    function getCaptainLocation(captainLocation) {
      if (captainLocation && captainLocation.lat && captainLocation.lng) {
        return captainLocation.lat + '\n' + captainLocation.lng
      }
      return ''
    }

    function getCaptainPositionInMappedCaptainList(captainId, mappedCaptainList) {
      if (!mappedCaptainList) return ''

      return `${mappedCaptainList.indexOf(captainId) + 1} / ${mappedCaptainList.length}`
    }

    function getReadableDateFromEpoch(epoch) {
      const date = new Date(epoch);
      return date.toLocaleString();
    }

    this.getPropagationEvents(fromDate, toDate).subscribe(async ({ events, count }) => {
      const self = this
      function createTableData(value, className?, hoverData?, flag = false, suspendFlag = false) {
        return new TableData({
          data: value,
          hoverData: hoverData ? hoverData : null,
          type: TableDataTypes.DATA,
          className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
          onClick: (data) => {
            const newRelativeUrl = self.router.createUrlTree([`/order/${data.orderId}`]);
            const baseUrl = window.location.href.replace(self.router.url, '');
            window.open(baseUrl + newRelativeUrl, '_blank');
          }
        });
      }

      this.propagationEvents = events.map((event) => {
        const orderId = event.orderId;
        return {
          date: createTableData(event.orderDate),
          orderId: createTableData(event.orderId),
          eventName: createTableData(event.eventName),
          serviceDetailId: createTableData(event.serviceDetailId),
          updatedAt: createTableData(getReadableDateFromEpoch(event.updatedAt)),
          captainLocation: createTableData(getCaptainLocation(event.captainLocation)),
          captainPositionInMappedList: createTableData(getCaptainPositionInMappedCaptainList(this.captainObjectId, event.mappedCaptains))
        };
      });
      this.propagationEventsTable.data = this.propagationEvents;
      this.propagationEventsTable.pagination.count = count;
    }, err => {
      console.log('error while fetching propagtion events :', err)
      this.propagationEventsTable.data = [];
      this.propagationEventsTable.pagination.count = 0;
    })
  }
}
