import { MyHttpClient } from '../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../http-client.service";
var OrderDetailsService = /** @class */ (function () {
    function OrderDetailsService(http) {
        this.http = http;
        this.server = environment.server;
        this.baseOrderUrl = this.server + '/api/orders';
        this.tipUrl = this.server + '/api/tip';
        this.getCustAdj = this.server + '/api/payments/getCustomerAdjustments';
        this.getCaptAdj = this.server + '/api/payments/getCaptainAdjustments';
        this.prevDuesTransactions = this.server + '/api/payments/getPrevDuesTransactions';
        this.cancelUrl = this.server + '/api/orderOrchestartor/cancel';
        this.fareEstimatesUrl = this.server + '/api/getFareEstimates';
        this.getCorrelationData = this.server + '/api/getCorrelationIds';
        this.getOfflineRechargeUrl = this.server + '/api/payments/getOfflineRecharge';
        this.s3ImageFetch = this.server + '/api/fetchImageFromS3';
        this.b2bOrderDetails = this.server + '/api/b2b/deliveryOrders/';
        this.batchedOrders = this.server + '/api/batchingInfo/';
        this.ordersV2 = this.server + '/api/v2/orders';
        this.orderPromise = this.server + '/api/orderOrchestartor/orderAcceptPromise';
    }
    OrderDetailsService.prototype.fetchOrderDetails = function (orderId) {
        return this.http.get(this.baseOrderUrl + '/' + orderId + '/details').pipe(catchError(this.handleError));
    };
    OrderDetailsService.prototype.cancel = function (orderDetails) {
        return this.http.post(this.cancelUrl, orderDetails).pipe(catchError(this.handleError));
    };
    OrderDetailsService.prototype.fetchOrderDetailsV2 = function (orderId, qos, type, email, agentId, roles) {
        return this.http.get(this.ordersV2 + "/" + orderId + "?qos=" + qos + "&type=" + type + "&email=" + email + "&agentId=" + agentId + "&roles=" + roles).pipe(catchError(this.handleError));
    };
    OrderDetailsService.prototype.fetchOrderAcceptPromise = function (orderId) {
        return this.http.get(this.orderPromise + "/" + orderId).pipe(catchError(this.handleError));
    };
    OrderDetailsService.prototype.fetchCaptainRateCard = function (orderId) {
        return this.http.get(this.server + "/api/order/" + orderId + "/captainRateCard").pipe(catchError(this.handleError));
    };
    OrderDetailsService.prototype.fetchDetailedOrderEarnings = function (orderId) {
        return this.http.get(this.server + '/api/captain/earnings/' + orderId).pipe(catchError(this.handleError));
    };
    OrderDetailsService.prototype.fetchInvoiceReceiptDetails = function (orderId) {
        return this.http.get(this.server + '/api/invoice/receipt/' + orderId).pipe(catchError(this.handleError));
    };
    OrderDetailsService.prototype.fetchB2bOrderDetails = function (orderId) {
        return this.http.get(this.b2bOrderDetails + orderId).pipe(catchError(this.handleError));
    };
    OrderDetailsService.prototype.getTip = function (orderId, riderId, paymentDate) {
        return this.http.get(this.tipUrl + '/' + orderId + '/' + riderId + '/' + paymentDate).pipe(catchError(this.handleError));
    };
    OrderDetailsService.prototype.getCustomerAdjustments = function (orders, id) {
        return this.http.post(this.getCustAdj + '/' + id, orders).pipe(catchError(this.handleError));
    };
    OrderDetailsService.prototype.getPrevDuesTransactions = function (userId) {
        var body = {
            userId: userId,
            showAllTransactions: true
        };
        return this.http.post(this.prevDuesTransactions, body).pipe(catchError(this.handleError));
    };
    OrderDetailsService.prototype.getCaptainAdjustments = function (orderId, riderId) {
        var body = {
            orderId: orderId,
            riderId: riderId
        };
        return this.http.post(this.getCaptAdj, body).pipe(catchError(this.handleError));
    };
    OrderDetailsService.prototype.getFareEstimates = function (serviceId, requestIds, userId) {
        var body = {
            serviceId: serviceId,
            requestIds: requestIds,
            userId: userId
        };
        return this.http.post(this.fareEstimatesUrl, body).pipe(catchError(this.handleError));
    };
    OrderDetailsService.prototype.getCorrelationIds = function (orderId) {
        var payload = { orderId: orderId };
        return this.http.post(this.getCorrelationData, payload).pipe(catchError(this.handleError));
    };
    OrderDetailsService.prototype.getOfflineRecharge = function (customerId, riderId, orderDate) {
        return this.http.post(this.getOfflineRechargeUrl, {
            customerId: customerId,
            riderId: riderId,
            orderDate: orderDate,
        }).pipe(catchError(this.handleError));
    };
    OrderDetailsService.prototype.getImageFromS3 = function (imageUrl) {
        return this.http.post(this.s3ImageFetch, { imageUrl: imageUrl }, { responseType: 'blob' }).pipe(catchError(this.handleError));
    };
    OrderDetailsService.prototype.getBatchingInfo = function (batchId) {
        return this.http.get(this.batchedOrders + batchId).pipe(catchError(this.handleError));
    };
    OrderDetailsService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    OrderDetailsService.ngInjectableDef = i0.defineInjectable({ factory: function OrderDetailsService_Factory() { return new OrderDetailsService(i0.inject(i1.MyHttpClient)); }, token: OrderDetailsService, providedIn: "root" });
    return OrderDetailsService;
}());
export { OrderDetailsService };
