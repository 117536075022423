/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../card-table/card-table.component.ngfactory";
import * as i2 from "../card-table/card-table.component";
import * as i3 from "@angular/router";
import * as i4 from "./captain-invoice-details-helper";
import * as i5 from "../log-service.service";
import * as i6 from "../http-client.service";
import * as i7 from "./captain-invoice-details.component";
import * as i8 from "../entity.service";
var styles_CaptainInvoiceDetailsComponent = [];
var RenderType_CaptainInvoiceDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CaptainInvoiceDetailsComponent, data: {} });
export { RenderType_CaptainInvoiceDetailsComponent as RenderType_CaptainInvoiceDetailsComponent };
export function View_CaptainInvoiceDetailsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-card-table", [], null, [[null, "click"], [null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("scroll" === en)) {
        var pd_1 = (_co.handleScroll($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_CardTableComponent_0, i1.RenderType_CardTableComponent)), i0.ɵdid(1, 638976, null, 0, i2.CardTableComponent, [i3.Router], { info: [0, "info"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.info; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CaptainInvoiceDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-captain-invoice-details", [], null, null, null, View_CaptainInvoiceDetailsComponent_0, RenderType_CaptainInvoiceDetailsComponent)), i0.ɵprd(512, null, i4.CaptainInvoiceHelperService, i4.CaptainInvoiceHelperService, []), i0.ɵprd(512, null, i5.LogService, i5.LogService, [i6.MyHttpClient]), i0.ɵdid(3, 638976, null, 0, i7.CaptainInvoiceDetailsComponent, [i4.CaptainInvoiceHelperService, i8.EntityService, i5.LogService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var CaptainInvoiceDetailsComponentNgFactory = i0.ɵccf("app-captain-invoice-details", i7.CaptainInvoiceDetailsComponent, View_CaptainInvoiceDetailsComponent_Host_0, { captainRateCard: "captainRateCard", captainInvoiceDetails: "captainInvoiceDetails", orderType: "orderType" }, {}, []);
export { CaptainInvoiceDetailsComponentNgFactory as CaptainInvoiceDetailsComponentNgFactory };
