import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CaptainLastLocationsService = /** @class */ (function () {
    function CaptainLastLocationsService(httpClient) {
        this.httpClient = httpClient;
        this.locationPingIcon = {
            url: '../../assets/map/actualRoute.png',
            scaledSize: new google.maps.Size(10, 10)
        };
    }
    CaptainLastLocationsService.prototype.constructLocationPing = function (locationPing) {
        return {
            location: {
                lat: locationPing.location[1],
                lng: locationPing.location[0],
            },
            date: new Date(locationPing.date)
        };
    };
    CaptainLastLocationsService.prototype.constructLocationPings = function (locations) {
        if (locations && locations.length == 0) {
            return [];
        }
        var locationPings = [];
        for (var _i = 0, locations_1 = locations; _i < locations_1.length; _i++) {
            var location_1 = locations_1[_i];
            locationPings.push(this.constructLocationPing(location_1));
        }
        return locationPings;
    };
    CaptainLastLocationsService.prototype.handleError = function (error) {
        return Promise.resolve({});
    };
    CaptainLastLocationsService.prototype.getCaptainPreviousLocationsByTimestamps = function (riderId, startTime, endTime) {
        var body = {
            userId: riderId,
            startedTime: startTime,
            droppedTime: endTime
        };
        return this.httpClient.post(environment.server + '/api/getActualRoute', body).pipe(catchError(this.handleError));
    };
    CaptainLastLocationsService.ngInjectableDef = i0.defineInjectable({ factory: function CaptainLastLocationsService_Factory() { return new CaptainLastLocationsService(i0.inject(i1.HttpClient)); }, token: CaptainLastLocationsService, providedIn: "root" });
    return CaptainLastLocationsService;
}());
export { CaptainLastLocationsService };
