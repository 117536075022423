var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, EventEmitter, OnChanges, SimpleChanges, } from '@angular/core';
import { Table, Pagination, TableData, TableDataTypes, } from 'src/app/shared/types';
import { MatDialog } from '@angular/material/dialog';
import { OrderService } from '../../order-service.service';
import { Router } from '@angular/router';
import { HelperService } from '../../helper.service';
import { FilterService, QuickFilters } from 'src/app/filter.service';
var currentDate = new Date();
var startDate = new Date();
startDate.setDate(startDate.getDate() - 1);
HelperService.resetTimeTo(currentDate);
HelperService.resetTimeTo(startDate);
export var DEFAULT_DATE = {
    FROM_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 31)),
    ONE_DAY_FROM_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 1)),
    CURRENT_FROM_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate())),
    EARNINGS_FROM_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 7)),
    TO_DATE: currentDate
};
function defaultFiters() {
    var startDuration = HelperService.getDateOnlyV1(new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 31)));
    var endDuration = HelperService.getDateOnlyV1(currentDate);
    return {
        startDuration: startDuration,
        endDuration: endDuration,
    };
}
var CaptainPropagationEventsComponent = /** @class */ (function () {
    function CaptainPropagationEventsComponent(dialog, orderService, filterService, router) {
        this.dialog = dialog;
        this.orderService = orderService;
        this.filterService = filterService;
        this.router = router;
        this.toggleHeaders = new EventEmitter();
        this.fromDate = DEFAULT_DATE.FROM_DATE;
        this.toDate = DEFAULT_DATE.TO_DATE;
        this.filtersApplied = defaultFiters();
    }
    CaptainPropagationEventsComponent.prototype.ngOnInit = function () {
        this.definePropagationEventsTable();
    };
    CaptainPropagationEventsComponent.prototype.ngOnChanges = function (changes) { };
    CaptainPropagationEventsComponent.prototype.orderDateFormat = function (date) {
        function padStart(length, data) {
            return data.toString().padStart(length, 0);
        }
        var dateString = date.getFullYear() + "-" + padStart(2, date.getMonth() + 1) + "-" + padStart(2, date.getDate());
        return dateString;
    };
    CaptainPropagationEventsComponent.prototype.declareTable = function (pageNumber) {
        var _this = this;
        if (pageNumber === void 0) { pageNumber = 0; }
        this.propagationEventsTable = new Table({
            tableHeader: {
                toString: function () {
                    return "Propagtion Events";
                },
            },
            defaultError: 'No propagtion records found',
            headers: {
                date: 'Order Date',
                updatedAt: 'Updated At',
                orderId: 'OrderId',
                eventName: 'Event',
                serviceDetailId: 'ServiceDetailId',
                captainLocation: 'Captain Location',
                captainPositionInMappedList: 'Captain position in mapped list' + '\n' + '( position / total captains )'
            },
            selectedHeader: 10,
            toggleableHeaders: this.captainDetailsToggleableHeaders,
            pagination: new Pagination(pageNumber, 10),
            config: {
                refresh: true,
            },
            onRefresh: function () {
                _this.propagationEventsTable = null;
                _this.definePropagationEventsTable();
            },
            filterComponents: [
                new QuickFilters({
                    key: 'duration',
                    default: '',
                    title: 'Duration',
                }),
            ],
            appliedFilters: this.filtersApplied,
            onFilter: function () {
                var timeSlot = _this.filterService.appliedFilters.duration || ['', ''];
                var duration = [];
                if (!Array.isArray(timeSlot)) {
                    duration.push(timeSlot['start']);
                    duration.push(timeSlot['end']);
                }
                else {
                    duration = timeSlot;
                }
                var startDuration = duration[0];
                var endDuration = duration[1];
                var filtersApplied = {
                    duration: {
                        start: startDuration,
                        end: endDuration,
                    },
                    startDuration: startDuration,
                    endDuration: endDuration,
                };
                Object.assign(_this.filtersApplied, filtersApplied);
                var isValidNumber = function (value) {
                    return ((parseInt(value, 10) === 0) || value);
                };
                if (startDuration && endDuration) {
                    startDuration = HelperService.isValidDate(new Date(startDuration)) ? new Date(startDuration) : undefined;
                    endDuration = HelperService.isValidDate(new Date(endDuration)) ? new Date(endDuration) : undefined;
                    return _this.definePropagationEventsTable(startDuration, endDuration);
                }
                return _this.definePropagationEventsTable();
            },
            data: [],
        });
    };
    CaptainPropagationEventsComponent.prototype.onPaginationChange = function (event) {
        this.propagationEventsTable.data = [];
        this.fillDataToTable(this.fromDate ? this.fromDate : DEFAULT_DATE.FROM_DATE, this.toDate ? this.toDate : DEFAULT_DATE.TO_DATE, this.filtersApplied);
    };
    CaptainPropagationEventsComponent.prototype.changeInHeader = function (header) {
        this.toggleHeaders.emit(header);
    };
    CaptainPropagationEventsComponent.prototype.definePropagationEventsTable = function (fromDate, toDate) {
        if (fromDate === void 0) { fromDate = DEFAULT_DATE.FROM_DATE; }
        if (toDate === void 0) { toDate = DEFAULT_DATE.TO_DATE; }
        this.declareTable();
        this.fromDate = fromDate;
        this.toDate = toDate;
        this.fillDataToTable(fromDate, toDate, this.filtersApplied);
    };
    CaptainPropagationEventsComponent.prototype.getPropagationEvents = function (fromDate, toDate) {
        var startDate = this.orderDateFormat(fromDate);
        var endDate = this.orderDateFormat(toDate);
        return this.orderService.getPropagationEventsForCaptain(this.captainObjectId, this.propagationEventsTable.pagination, startDate, endDate);
    };
    CaptainPropagationEventsComponent.prototype.fillDataToTable = function (fromDate, toDate, filters) {
        var _this = this;
        this.propagationEventsTable.dataWillLoad();
        function getCaptainLocation(captainLocation) {
            if (captainLocation && captainLocation.lat && captainLocation.lng) {
                return captainLocation.lat + '\n' + captainLocation.lng;
            }
            return '';
        }
        function getCaptainPositionInMappedCaptainList(captainId, mappedCaptainList) {
            if (!mappedCaptainList)
                return '';
            return mappedCaptainList.indexOf(captainId) + 1 + " / " + mappedCaptainList.length;
        }
        function getReadableDateFromEpoch(epoch) {
            var date = new Date(epoch);
            return date.toLocaleString();
        }
        this.getPropagationEvents(fromDate, toDate).subscribe(function (_a) {
            var events = _a.events, count = _a.count;
            return __awaiter(_this, void 0, void 0, function () {
                function createTableData(value, className, hoverData, flag, suspendFlag) {
                    if (flag === void 0) { flag = false; }
                    if (suspendFlag === void 0) { suspendFlag = false; }
                    return new TableData({
                        data: value,
                        hoverData: hoverData ? hoverData : null,
                        type: TableDataTypes.DATA,
                        className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
                        onClick: function (data) {
                            var newRelativeUrl = self.router.createUrlTree(["/order/" + data.orderId]);
                            var baseUrl = window.location.href.replace(self.router.url, '');
                            window.open(baseUrl + newRelativeUrl, '_blank');
                        }
                    });
                }
                var self;
                var _this = this;
                return __generator(this, function (_b) {
                    self = this;
                    this.propagationEvents = events.map(function (event) {
                        var orderId = event.orderId;
                        return {
                            date: createTableData(event.orderDate),
                            orderId: createTableData(event.orderId),
                            eventName: createTableData(event.eventName),
                            serviceDetailId: createTableData(event.serviceDetailId),
                            updatedAt: createTableData(getReadableDateFromEpoch(event.updatedAt)),
                            captainLocation: createTableData(getCaptainLocation(event.captainLocation)),
                            captainPositionInMappedList: createTableData(getCaptainPositionInMappedCaptainList(_this.captainObjectId, event.mappedCaptains))
                        };
                    });
                    this.propagationEventsTable.data = this.propagationEvents;
                    this.propagationEventsTable.pagination.count = count;
                    return [2 /*return*/];
                });
            });
        }, function (err) {
            console.log('error while fetching propagtion events :', err);
            _this.propagationEventsTable.data = [];
            _this.propagationEventsTable.pagination.count = 0;
        });
    };
    return CaptainPropagationEventsComponent;
}());
export { CaptainPropagationEventsComponent };
