import { Injectable } from "@angular/core";
import * as faker from "faker";
import { Observable, forkJoin } from "rxjs";
import { of } from "rxjs";
import { EntityService } from "./entity.service";
import "rxjs/add/operator/map";
import { environment } from "src/environments/environment";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { shareReplay, map, mergeMap, catchError } from "rxjs/operators";
import { MyHttpClient } from "./http-client.service";
import { HelperService } from "./helper.service";
import { DateFilterInput } from "./filter.service";
import { DataService } from "./data.service";
import { HandHoldingCaptain } from "./shared/types/captain.types";
// import { LoaderService } from './loader.service';
const DEFAULT = "N/A";
const STATUSES = {
  ON_DUTY: "OnDuty",
  ON_DUTY_ON_WAY: "onDutyOnWay",
  LOGOUT: "Logout",
  UNREACHABLE: "Unreachable",
  ON_THE_WAY: "onTheWay",
  ARRIVED: "arrived",
  STARTED: "started",
  STARTED_ON_WAY: "startedOnWay",
  STARTED_ON_DUTY: "startedOnDuty",
  OFFLINE: "offline",
};

const STATUS_MAPPING = {
  "2": STATUSES.ON_DUTY,
  "2-6": STATUSES.ON_DUTY_ON_WAY,
  "4": STATUSES.LOGOUT,
  "5": STATUSES.UNREACHABLE,
  "6": STATUSES.ON_THE_WAY,
  "7": STATUSES.ARRIVED,
  "8": STATUSES.STARTED,
  "8-6": STATUSES.STARTED_ON_WAY,
  "8-2": STATUSES.STARTED_ON_DUTY,
  "9": STATUSES.OFFLINE,
};

const CONNECTION_STATUS_MAPPING = {
  [STATUSES.ON_DUTY]: "online",
  [STATUSES.ON_DUTY_ON_WAY]: "online",
  [STATUSES.LOGOUT]: "offline",
  [STATUSES.UNREACHABLE]: "offline",
  [STATUSES.ON_THE_WAY]: "online",
  [STATUSES.ARRIVED]: "online",
  [STATUSES.STARTED]: "online",
  [STATUSES.STARTED_ON_WAY]: "online",
  [STATUSES.STARTED_ON_DUTY]: "online",
  [STATUSES.OFFLINE]: "offline",
};

@Injectable()
export class CaptainService {
  constructor(
    public entityService: EntityService,
    public httpClient: HttpClient,
    private dataService: DataService // public loaderService: LoaderService,
  ) {}
  private cache: any = {};
  private cachedObservable: any = {};
  private deviceName({ manufacturer, model }) {
    return `${manufacturer} ${model}`;
  }
  captainDetails(
    captainId: string,
    startDate?: Date,
    endDate?: Date,
    refresh = false,
    withEarnings = true,
    withReferralDetails = false,
    type?: string,
    email?: string,
    agentId?: string,
    roles?: Array<string>
  ) {
    const fromDate = (startDate && new Date(startDate)) || new Date(2010, 1, 1);
    const toDate = (endDate && new Date(endDate)) || new Date();
    const uniqueId = () => {
      return `captainDetails-${captainId}-${fromDate}-${toDate}`;
    };
    let captainEarningObs = Observable.of({});
    if (withEarnings) {
      captainEarningObs = this.captainEarnings(
        captainId,
        { fromDate, toDate },
        refresh
      );
    }
    this.cachedObservable[uniqueId()] = forkJoin(
      captainEarningObs,
      this.entityService.getEntityByNumber(captainId, type, email, agentId, roles)
    )
      .pipe(
        mergeMap(([captainEarnings, captainDetailsRes]) => {
          const data = captainDetailsRes;
          const userId = data.userId._id;
          const userDetails = data.userId || {};
          const shift = data.shift || {};
          data.tl = data.tl || {};
          data.services = data.services || [];
          const activatedDate =
            data.statusUpdates && data.statusUpdates.activatedOn;
          const captainDetails = {
            active: data.active,
            imageUrl: data.profilePicture.link,
            mobileNumber: data.mobile || DEFAULT,
            name: `${data.firstName} ${data.lastName}` || DEFAULT,
            emailid: data.email || DEFAULT,
            lastUserDevice: this.deviceName(data.device) || DEFAULT,
            referralCode: userDetails.referralCode || [],
            registeredOn: data.registeredDate || DEFAULT,
            languages: data.languages.join(", ") || DEFAULT,
            registeredCity: data.registeredCity || DEFAULT,
            activatedDate: activatedDate || DEFAULT,
            shift: shift.name || DEFAULT,
            service:
              data.services.map((service) => service.service.name).join(", ") ||
              DEFAULT,
            tlName: data.tl.firstName || DEFAULT,
            tlEmailId: data.tl.email || DEFAULT,
            status: userDetails.status,
            riderId: data.userId && data.userId._id,
            connectionStatus:
              CONNECTION_STATUS_MAPPING[STATUS_MAPPING[userDetails.status]],
            vehicleDetails: data.currentVehicle,
            license: data.license,
            city: data.city,
            serviceList: data.services,
            userId: data.userId && data.userId._id,
            mode: data.modeId && data.modeId.mode,
          };
          const result = Object.assign(captainDetails, captainEarnings);
          if(withReferralDetails){
            return this.entityService
            .getReferralInfo(userId)
            .map((referralCode: any) => {
              result.referralCode =
                referralCode && referralCode.length
                  ? referralCode.join(", ").trim()
                  : "N/A";
              return result;
            });
          }
          else{
            return Observable.of(result);
          }
          
        })
      )
      .catch((err) => {
        console.log(err);
        throw new Error("Invalid UserName");
      })
      .pipe(shareReplay(2));
    return this.cachedObservable[uniqueId()];
  }
  captainEarnings(mobileNumber: string, filter: any = {}, refresh = false) {
    const from = filter.fromDate;
    const to = filter.toDate;
    let startDate = from || new Date(2010, 1, 1);
    let endDate = to || new Date();
    const duration = DateFilterInput.formatDurationForQuery({
      fromDate: startDate,
      toDate: endDate,
    });
    startDate = duration.fromDate;
    endDate = duration.toDate;
    const uniqueId = () => {
      return `captainEarnings-${mobileNumber}-${startDate}-${endDate}`;
    };
    const url = `${environment.server}/api/rider/earnings/${mobileNumber}`;
    return forkJoin(
      this.httpClient.post(url, {
        startDate,
        endDate,
      }),
      this.lastRedeemInfoByMobileNumber(mobileNumber),
      this.autoRedeemInfoByMobileNumber(mobileNumber),
      this.getCityNegativeBalanceThresholdByMobileNumber(mobileNumber)
    )
      .map(([res, redeemInfo, autoRedeemData, cityNegativeThreshold]) => {
        const walletDetails = res["walletDetails"] || {};
        res = res["earningDetails"] || {};
        const details = res["detail"] || {};
        const lastRedeemRequest = redeemInfo.data.lastRedeemRequest || {};
        const previousRedeems = redeemInfo.data.previousRedeems || [];
        const redeemFrequency = redeemInfo.data.redeemRulesData[0] ? redeemInfo.data.redeemRulesData[0].transactionPeriod : '-';
        const redeemLimit = redeemInfo.data.redeemRulesData[0] ? redeemInfo.data.redeemRulesData[0].transactionCount : '-';
        const redeemLeft = redeemLimit && redeemLimit != '-' && redeemInfo.data.redeemLeft != undefined ? redeemInfo.data.redeemLeft.toString() : '-';
        const blockingLimit = cityNegativeThreshold.blockingLimit ? cityNegativeThreshold.blockingLimit : '-'
        let isAutoRedeemEnabled;


        if(autoRedeemData && autoRedeemData.config){
          if(autoRedeemData.config.isEnabled)
          {
            isAutoRedeemEnabled = 'ON';
          }
          else if(autoRedeemData.config.isAvailable == false){
            isAutoRedeemEnabled = 'NA';
          }
          else if(autoRedeemData.config.isAvailable && autoRedeemData.config.isEnabled == false){
            isAutoRedeemEnabled = 'OFF';
          }
        }
        else{
          isAutoRedeemEnabled = 'NA';
        }
        let status, date, amount, remarks;
        if (!Object.keys(lastRedeemRequest).length) {
          const latestRedeemApproved = previousRedeems[0] || {};
          status = latestRedeemApproved.status;
          amount = latestRedeemApproved.amount;
          date = latestRedeemApproved.date;
        } else {
          const updateLog = lastRedeemRequest.updateLog || [];
          const latestUpdateLog = updateLog || {};
          remarks = latestUpdateLog.remarks;
          date = lastRedeemRequest.date;
          status = lastRedeemRequest.status;
          amount = lastRedeemRequest.amount;
        }
        this.cache[uniqueId()] = {
          totalEarning: details.finalAmount,
          totalIncentive:
            (details.specialincentives || 0) + (details.boosterIncentive || 0),
          wallet: details.wallet,
          lastRedeemStatus: status || "-",
          lastRedeemDate: date || "-",
          lastRedeemAmt: HelperService.roundNumber(amount) || "-",
          walletBalance: walletDetails.balance,
          redeemRemark: remarks,
          accountSummary: redeemInfo.data.accountSummary,
          redeemFrequency: redeemFrequency || "-",
          redeemLimit: redeemLimit || "-",
          redeemLeft: redeemLeft || "-",
          isAutoRedeemEnabled: isAutoRedeemEnabled || "NA",
          blockingLimit: blockingLimit || "-"
        };
        return this.cache[uniqueId()];
      })
      .catch((err) => {
        console.log(err);
        throw new Error("Invalid UserName");
      });
  }

  getLoginHour(fromDate: string, toDate: string, riderId: string) {
    return this.httpClient
      .post(`${environment.server}/api/captain/loginHour`, {
        fromDate,
        toDate,
        riderId,
      })
      .map((res: any) => {
        res = res || {};
        return res[riderId] || "";
      })
      .catch((err, caught) => {
        return Observable.of("");
      });
  }

  fraudStat({ riderId, startDuration, endDuration }) {
    return this.httpClient
      .get(
        `${environment.server}/api/fraudEngine/fraudOrderStatus/grouped/${riderId}`,
        {
          params: {
            fromDate: HelperService.timeStampToDateOnly(startDuration),
            endDuration: HelperService.timeStampToDateOnly(endDuration),
          },
        }
      )
      .map((res: any) => {
        return res.data;
      })
      .catch((err, caught) => {
        return Observable.of([]);
      });
  }

  captainLiveStat(mobileNumber) {
    const url = `${environment.server}/api/rider/liveStat/${mobileNumber}`;
    return this.httpClient.get(url).map((res) => {
      return res;
    });
  }

  lastRedeemInfo(riderId): Observable<any> {
    const url = `${environment.server}/api/get/rider/redeem/summary/${riderId}`;
    return this.httpClient.get(url).map((res) => {
      return res;
    });
  }

  autoRedeemData(riderId): Observable<any> {
    const url = `${environment.server}/api/get/rider/autoRedeem/riderAutoRedeemData/${riderId}`;
    return this.httpClient.get(url).map((res) => {
      return res;
    }).pipe(catchError(this.handleError));
  }

  redeemCaptain(riderId, shiftName, city, userId) {
    const url = `${environment.server}/api/enable/redeem`;
    const payload = {
      id: riderId,
      shift: shiftName,
      city: city,
      userId: userId,
    };
    return this.httpClient.post(url, payload).map((res) => {
      return res;
    });
  }

  logoutCaptain(riderId) {
    const url = `${environment.server}/api/logoutCaptain`;
    const body = {
      riderId: riderId,
    };
    return this.httpClient.post(url, body).map((res) => {
      return res;
    });
  }

  lastRedeemInfoByMobileNumber(mobileNumber: string): Observable<any> {
    // return Observable.of({"status":200,"message":"Ok","data":{"earnings":{"totalEarning":5202.85,"cashCollected":5056,"orders":116,"balance":19.8},"lastPayout":"2020-07-11","previousRedeems":[{"status":"Credited","date":"2020-07-11","amount":132.76,"createdOn":1594485817068,"uniqueId":"f929eb2332e771356c9a3f80fd8ff205","transactionType":"redeem","accountDetails":{},"upiDetails":{"upiId":"8686581049@upi"}},{"status":"Credited","date":"2020-07-10","amount":106.56,"createdOn":1594377240045,"uniqueId":"d894e8cf88d79d473552b74e156f2e0a","transactionType":"redeem","accountDetails":{},"upiDetails":{"upiId":"8686581049@upi"}},{"status":"Credited","date":"2020-06-29","amount":49.58,"createdOn":1593445473206,"uniqueId":"1082dc2fcf5519f289fe69d80f3d8473","transactionType":"redeem","accountDetails":{},"upiDetails":{"upiId":"8686581049@upi"}}],"lastRedeemRequest":{},"redeemRulesData":[{"minTransactionAmount":1,"firstRedeemMinAmount":1,"shiftName":"Flexi_CM","termsAndCondition":["- Maximum 2 transactions are allowed per week.  - It is the responsibility of the captain to provide correct bank account number and other details. In case of wrong transfer to an account, only the captain is responsible and liable. - The company is eligible to hold your payment in case of suspicion of fraudulent or criminal activity.  - For any query, kindly contact your Team Support."],"transactionPeriod":"weekly","weekDays":["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"],"transactionCount":2,"cityName":"Hyderabad"}]}})
    return this.entityService
      .getEntityByNumber(mobileNumber)
      .pipe(
        mergeMap((res) => {
          const data = res;
          this.dataService.setCurrentEntity(data);
          return this.lastRedeemInfo(data.userId._id);
        })
      )
      .catch((err) => {
        console.log(err);
        throw new Error("Invalid UserName");
      });
  }

  autoRedeemInfoByMobileNumber(mobileNumber: string): Observable<any> {
    return this.entityService
      .getEntityByNumber(mobileNumber)
      .pipe(
        mergeMap((res) => {
          const data = res;
          this.dataService.setCurrentEntity(data);
          return this.autoRedeemData(data.userId._id);
        })
      )
      .catch((err) => {
        throw new Error("Invalid UserName");
      });
  }

  getCityNegativeBalanceThresholdByMobileNumber(mobileNumber: string): Observable<any> {
    return this.entityService
      .getEntityByNumber(mobileNumber)
      .pipe(
        mergeMap((res) => {
          const data = res;
          this.dataService.setCurrentEntity(data);
          return this.getCityNegativeBalanceThreshold(data.userId._id, data.city._id);
        })
      )
      .catch((err) => {
        throw new Error("Invalid UserName");
      });
  }

  getCityNegativeBalanceThreshold(captainId : any, cityId : any){
    const url = `${environment.server}/api/rider/negativeBalanceThresholdInfo`;
    const body = {
      userId : captainId,
      cityId : cityId
    }
    return this.httpClient.post(url, body).map((res) => {
      return res;
    }).pipe(catchError(this.handleError));

  }

  getUserSelectorsOfRider(riderId) {
    const url = `${environment.server}/api/b2b/crossUtilization/selectors/rider/${riderId}`;
    return this.httpClient.get(url).map((res) => {
      return res;
    }).pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    // Temporarily returing an empty array whenever there is a failure while getting cross utilisation
    return Promise.resolve({});
  }

  removeRiderFromUserSelector(requestBody) {
    const { riderId, selectorId } = requestBody;
    const url = `${environment.server}/api/b2b/crossUtilization/selectors/rider/remove`;

    return this.httpClient.post(url, { riderId, selectorId }).map((res) => {
      return res;
    });
  }

  incentiveProgress(riderId, startDate, endDate) {
    const url = `${environment.server}/api/rider/incentiveProgress`;
    return this.httpClient
      .post(url, { riderId, startDate, endDate })
      .map((res) => {
        return res;
      });
  }

  getCaptainAdjustment(riderId, startDate, endDate) {
    const url = `${environment.server}/api/rider/adjustment`;
    return this.httpClient
      .post(url, { riderId, startDate, endDate })
      .map((res) => {
        return res ? res : {};
      });
  }

  suspendCaptain(requestParams) {
    const url = `${environment.server}/api/userPreference/suspendGroup`;
    return this.httpClient.post(url, requestParams).map((res) => {
      return res ? res : {};
    });
  }

  unsuspendCaptain(requestParams) {
    const url = `${environment.server}/api/userPreference/unsuspendGroup`;
    return this.httpClient.post(url, requestParams).map((res) => {
      return res ? res : {};
    });
  }

  getUserServices(captainId, getServiceGroups = false) {
    const url = `${environment.server}/api/rider/eligibility/${captainId}?getServiceGroups=${getServiceGroups}`;
    return this.httpClient.post(url, {}).map((res) => {
      return res || [];
    });
  }

  getSuspendedServices(captainId) {
    const url = `${environment.server}/api/rider/eligibility/${captainId}`;
    return this.httpClient.post(url, {}).map((res) => {
      return res || [];
    });
  }

  getRapidoPayActivityLogs(captainId, limit, offset) {
    const url = `${environment.server}/api/rapidoPayLogs/${captainId}`;

    return this.httpClient
      .post(url, { limit, offset: offset * 10 })
      .map((res) => {
        return res || [];
      });
  }

  getRapidoPayBlockedUser(captainId) {
    const url = `${environment.server}/api/blockedRapidoPay/${captainId}`;

    return this.httpClient.get(url).map((res) => {
      return res || [];
    });
  }

  getHandHoldingCaptainDetails(
    captainId: string
  ): Observable<HandHoldingCaptain> {
    const url = `${environment.server}/api/handholding/${captainId}`;
    return this.httpClient.get<HandHoldingCaptain>(url);
  }

  getCaptainAllRateCards(riderId, lat, lng){
    return this.httpClient.get(environment.server + '/api/captain/allRateCards/'+ riderId,
      {
        params: lat & lng ? { lat, lng } : {},
      },
    ).pipe(catchError(this.handleError));
  }
}
