<div class="boxed-border">
  <div class="title row">
    <span>
      Captain last locations
    </span>
    <div class="pull-right">
      <fa-icon class="cursor-pointer margin-icons" (click)="reset()" [icon]="faRefresh" style="color: black"></fa-icon>
    </div>
  </div>
  <div class="line"></div>
  <div class="row padding-30">
    <div class="col-md-4 basic-container boxed-border">
      <form [formGroup]="timeInputFormGroup" (ngSubmit)="onSubmit()">
        <div class="row mb-3">
          <div class="col-md-10">
            <mat-form-field class="width-100">
              <input matInput [matDatepicker]="picker" placeholder="Select Date" formControlName="date">
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-icon (click)="picker.open()">calendar_today</mat-icon>
          </div>
        </div>
        <div class="row mb-3">
          <mat-form-field class="width-100">
            <input matInput type="time" formControlName="startTime" step="1" placeholder="Start Time HH:MM:SS">
          </mat-form-field>
        </div>
        <div class="row mb-3">
          <mat-form-field class="width-100">
            <input matInput type="time" formControlName="endTime" step="1" placeholder="End Time: HH:MM:SS">
          </mat-form-field>
        </div>
        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </div>
    <div class="col-md-8 basic-container boxed-border">
      <div #map style="width:100%; height:305px;"></div>
    </div>
  </div>
</div>