import { Injectable } from '@angular/core';
import { LocationPing } from './shared/types/location.types';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CaptainLastLocationsService {

  locationPingIcon = {
    url: '../../assets/map/actualRoute.png',
    scaledSize: new google.maps.Size(10, 10)
  };

  constructor(public httpClient: HttpClient) { }


  constructLocationPing(locationPing: any): LocationPing {
    return {
      location: {
        lat: locationPing.location[1],
        lng: locationPing.location[0],
      },
      date: new Date(locationPing.date)
    };
  }

  constructLocationPings(locations: any[]): LocationPing[] {
    if (locations && locations.length == 0) {
      return [];
    }
    const locationPings: LocationPing[] = [];
    for (const location of locations) {
      locationPings.push(this.constructLocationPing(location));
    }
    return locationPings;
  }

  handleError(error: HttpErrorResponse) {
    return Promise.resolve({});
  }

  getCaptainPreviousLocationsByTimestamps(riderId, startTime, endTime) {
    const body = {
      userId: riderId,
      startedTime: startTime,
      droppedTime: endTime
    };
    return this.httpClient.post(environment.server + '/api/getActualRoute',
      body).pipe(catchError(this.handleError));
  }
}
