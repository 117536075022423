var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerAdjustmentService } from './cust-adjustment.service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RoleBasedAccessService } from '../role-based-access.service';
import { CUSTOMER_REFUND_ACCESS, CUSTOMER_DEBIT_ACCESS, CUSTOMER } from '../roles.constants';
import { AppConfigService } from '../appConfig.service';
var ADJUSTMENT_TYPES = {
    REFUND: 'Refund',
    DEBIT: 'Debit',
    CREDIT: 'Credit'
};
var CustAdjustmentComponent = /** @class */ (function () {
    function CustAdjustmentComponent(route, customerAdjService, toasterService, dialog, appConfigService, roleBasedAccess) {
        this.route = route;
        this.customerAdjService = customerAdjService;
        this.toasterService = toasterService;
        this.dialog = dialog;
        this.appConfigService = appConfigService;
        this.roleBasedAccess = roleBasedAccess;
        this.roles = this.accessCookie('Roles').split(',');
        this.unavailableStatuses = ['new', 'onTheWay', 'arrived', 'started'];
        this.statusValue = [];
        this.tickets = [];
        this.logData = {};
        this.coinsCreditReasons = [];
        this.debitCustReasons = [];
        this.refundCustReasons = [];
        this.adjustmentDone = new EventEmitter();
        this.wallets = {
            rapido: "Rapido Wallet",
            prevDue: "Previous Due",
            lazypay: "Lazypay",
            freecharge: "Freecharge",
            simpl: "Simpl",
            gpay: "Gpay/JusPay",
            paytm: "Paytm",
            amazonpay: "Amazon Pay",
            coins: "Coins",
            upi: "Upi"
        };
        this.walletOptions = [
            this.wallets.rapido,
            this.wallets.prevDue,
            this.wallets.paytm,
            this.wallets.freecharge,
            this.wallets.lazypay,
            this.wallets.simpl,
            this.wallets.gpay,
            this.wallets.amazonpay,
            this.wallets.upi,
        ];
        this.debitWalletOptions = [
            this.wallets.rapido,
            this.wallets.prevDue
        ];
        this.creditWalletOptions = [
            this.wallets.coins
        ];
        this.info = {
            header: 'Customer Adjustment',
            headerClass: null,
            dataFetched: true,
            rows: []
        };
        this.success = false;
    }
    CustAdjustmentComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var adjustmentConfig;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.orderId = this.route.snapshot.params.id;
                        this.accessRoles = this.accessCookie('accessRoles');
                        return [4 /*yield*/, this.appConfigService.getAdjustmentConfig()];
                    case 1:
                        adjustmentConfig = _a.sent();
                        this.coinsCreditReasons = JSON.parse(adjustmentConfig.creditReasonsOrder);
                        this.debitCustReasons = JSON.parse(adjustmentConfig.debitReasonsOrder);
                        this.refundCustReasons = JSON.parse(adjustmentConfig.refundReasonsOrder);
                        this.checkStatus();
                        return [2 /*return*/];
                }
            });
        });
    };
    CustAdjustmentComponent.prototype.checkStatus = function () {
        // Push Refund if logged in user has Access for it.
        var refundAccess = Object.keys(CUSTOMER_REFUND_ACCESS);
        if ((this.roles.some(function (redundRole) { return refundAccess.indexOf(redundRole) >= 0; })) && this.statusValue.indexOf('Refund') < 0) {
            this.statusValue.push('Refund');
        }
        // Push Debit if logged in user has Access for it.
        var debitAccess = Object.keys(CUSTOMER_DEBIT_ACCESS);
        if ((this.roles.some(function (debitRole) { return debitAccess.indexOf(debitRole) >= 0; })) && this.statusValue.indexOf('Debit') < 0) {
            this.statusValue.push('Debit');
        }
        if (this.statusValue.indexOf('Credit') < 0) {
            this.statusValue.push('Credit');
        }
        this.constructData();
    };
    CustAdjustmentComponent.prototype.constructData = function () {
        if (this.statusValue.length > 0) {
            this.info['rows'] = [
                [
                    { titleClass: 'form-title-class', title: 'Action', type: 'single-searchable-dropdown', values: this.statusValue,
                        placeholder: 'Select status', key: 'cust-adj-action', default: undefined },
                    { titleClass: 'form-title-class', title: 'Ticket ID', type: 'text-input', placeholder: 'Ticket ID', key: 'cust-adj-ticket' },
                    { titleClass: 'form-title-class', title: 'Reason', type: 'single-searchable-dropdown', values: [],
                        placeholder: 'Select reason', key: 'cust-adj-reason', className: 'col-md-6' }
                ],
                [
                    { titleClass: 'form-title-class', title: 'Amount', type: 'text-input', placeholder: 'Enter Amount', key: 'cust-adj-amount' },
                    { titleClass: 'form-title-class', title: 'Wallet', type: 'single-searchable-dropdown', values: this.walletOptions,
                        placeholder: 'Select wallet', key: 'cust-adj-wallet', default: undefined }
                ]
            ];
        }
        else {
            this.info = {
                header: 'Customer Adjustment',
                accessDenied: true,
                dataFetched: true
            };
        }
    };
    CustAdjustmentComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.customerAdjDetails && changes.customerAdjDetails.currentValue) {
            if (changes.customerAdjDetails.currentValue.deliveryOrder) {
                this.emptyCard('Not available for Delivery orders');
            }
            else if (changes.customerAdjDetails.currentValue.status &&
                this.unavailableStatuses.indexOf(changes.customerAdjDetails.currentValue.status) !== -1) {
                this.emptyCard('Not available for the current order status');
            }
            else if (!changes.customerAdjDetails.currentValue.dataFetched) {
                this.emptyCard('Cannot load adjustment');
            }
        }
    };
    CustAdjustmentComponent.prototype.checkAccess = function (role) {
        if (this.accessRoles && this.accessRoles.indexOf(role) !== -1) {
            return true;
        }
        else {
            return false;
        }
    };
    CustAdjustmentComponent.prototype.emptyCard = function (message) {
        this.info = {
            header: 'Customer Adjustment',
            dataFetched: false,
            emptyMessage: message || 'No data available'
        };
    };
    CustAdjustmentComponent.prototype.changeRows = function (event) {
        if (event === 'Others') {
            this.info['rows'][1][2] = { titleClass: 'form-title-class', title: 'Other Reason', type: 'text-input',
                placeholder: 'Provide an explanation', key: 'cust-adj-other-reason' };
        }
        else if (event === ADJUSTMENT_TYPES.REFUND) {
            this.info['rows'] = [
                [
                    { titleClass: 'form-title-class', title: 'Action', type: 'single-searchable-dropdown', values: this.statusValue,
                        placeholder: 'Select status', key: 'cust-adj-action', default: event },
                    { titleClass: 'form-title-class', title: 'Ticket ID', type: 'text-input', placeholder: 'Ticket ID', key: 'cust-adj-ticket' },
                    { titleClass: 'form-title-class', title: 'Reason', type: 'single-searchable-dropdown',
                        values: this.refundCustReasons,
                        placeholder: 'Select reason', key: 'cust-adj-reason', className: 'col-md-6' }
                ],
                [
                    { titleClass: 'form-title-class', title: 'Amount', type: 'text-input', placeholder: 'Enter Amount', key: 'cust-adj-amount' },
                    { titleClass: 'form-title-class', title: 'Wallet', type: 'single-searchable-dropdown', values: this.walletOptions,
                        placeholder: 'Select wallet', key: 'cust-adj-wallet', default: undefined }
                ]
            ];
        }
        else if (event === ADJUSTMENT_TYPES.DEBIT) {
            this.info['rows'] = [
                [
                    { titleClass: 'form-title-class', title: 'Action', type: 'single-searchable-dropdown', values: this.statusValue,
                        placeholder: 'Select status', key: 'cust-adj-action', default: event },
                    { titleClass: 'form-title-class', title: 'Ticket ID', type: 'text-input', placeholder: 'Ticket ID', key: 'cust-adj-ticket' },
                    { titleClass: 'form-title-class', title: 'Reason', type: 'single-searchable-dropdown',
                        values: this.debitCustReasons,
                        placeholder: 'Select reason', key: 'cust-adj-reason', className: 'col-md-6' }
                ],
                [
                    { titleClass: 'form-title-class', title: 'Amount', type: 'text-input', placeholder: 'Enter Amount', key: 'cust-adj-amount' },
                    { titleClass: 'form-title-class', title: 'Wallet', type: 'single-searchable-dropdown', values: this.debitWalletOptions,
                        placeholder: 'Select wallet', key: 'cust-adj-wallet', default: undefined }
                ]
            ];
        }
        // coins credit
        else if (event === ADJUSTMENT_TYPES.CREDIT) {
            this.info['rows'] = [
                [
                    { titleClass: 'form-title-class', title: 'Action', type: 'single-searchable-dropdown', values: this.statusValue,
                        placeholder: 'Select status', key: 'cust-adj-action', default: event },
                    { titleClass: 'form-title-class', title: 'Ticket ID', type: 'text-input', placeholder: 'Ticket ID', key: 'cust-adj-ticket' },
                    { titleClass: 'form-title-class', title: 'Reason', type: 'single-searchable-dropdown',
                        values: this.coinsCreditReasons, placeholder: 'Select reason', key: 'cust-adj-reason', className: 'col-md-6' }
                ],
                [
                    { titleClass: 'form-title-class', title: 'Amount', type: 'text-input', placeholder: 'Enter Amount', key: 'cust-adj-amount' },
                    { titleClass: 'form-title-class', title: 'Wallet', type: 'single-searchable-dropdown', values: this.creditWalletOptions,
                        placeholder: 'Select wallet', key: 'cust-adj-wallet', }
                ]
            ];
        }
        else {
            for (var index = 0; index < this.info['rows'][1].length; index++) {
                if (this.info['rows'][1][index] && this.info['rows'][1][index].title === 'Other Reason') {
                    this.info['rows'][1].splice(index, 1);
                }
            }
        }
    };
    CustAdjustmentComponent.prototype.peformAdjustment = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var userDetails, confirmDialogRef, previousDue, rapidoWallet, walletAmount, reason, orderAmount, walletType, adjustmentType, maxAdjAmount, customer, adjustmentObj, customerCreatedOn;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userDetails = this.accessCookie('user');
                        userDetails = JSON.parse(userDetails);
                        previousDue = this.walletInfo.outstandingBalance;
                        rapidoWallet = this.walletInfo.response.find(function (wallet) { return wallet.type === "rapido"; });
                        walletAmount = this.coinPreference ? rapidoWallet.balance : rapidoWallet.rapidoWalletBalance;
                        orderAmount = this.customerAdjDetails.orderAmount;
                        //Validation
                        if (!event['cust-adj-action'] || !event['cust-adj-reason'] || !event['cust-adj-wallet'] || !event['cust-adj-ticket']) {
                            this.toasterService.showToaster(new Toaster({
                                type: ToasterType.WARNING,
                                message: "Please check customer adjustment details",
                            }));
                            return [2 /*return*/];
                        }
                        walletType = Object.keys(this.wallets).find(function (key) { return _this.wallets[key] === event['cust-adj-wallet']; });
                        adjustmentType = event['cust-adj-action'];
                        if (!event['cust-adj-amount'] || event['cust-adj-amount'] < 0) {
                            this.toasterService.showToaster(new Toaster({
                                type: ToasterType.WARNING,
                                message: adjustmentType + " amount should be greater than zero",
                            }));
                            return [2 /*return*/];
                        }
                        else if (adjustmentType === ADJUSTMENT_TYPES.REFUND && event['cust-adj-wallet'] === this.wallets.prevDue) {
                            if (event['cust-adj-amount'] > previousDue) {
                                this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.WARNING,
                                    message: adjustmentType + " amount should not be more than Previous Due",
                                }));
                                return [2 /*return*/];
                            }
                        }
                        else if (adjustmentType === ADJUSTMENT_TYPES.DEBIT && event['cust-adj-wallet'] === this.wallets.rapido) {
                            if (event['cust-adj-amount'] > walletAmount) {
                                this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.WARNING,
                                    message: adjustmentType + " amount should not be more than Rapido Wallet Amount",
                                }));
                                return [2 /*return*/];
                            }
                        }
                        else if (event['cust-adj-amount'] > orderAmount) {
                            this.toasterService.showToaster(new Toaster({
                                type: ToasterType.WARNING,
                                message: adjustmentType + " amount should not be more than Order Amount",
                            }));
                            return [2 /*return*/];
                        }
                        if (event['cust-adj-reason'] === 'Others' && !event['cust-adj-other-reason']) {
                            this.toasterService.showToaster(new Toaster({
                                type: ToasterType.WARNING,
                                message: "Please enter reason",
                            }));
                            return [2 /*return*/];
                        }
                        else if (event['cust-adj-reason'] === 'Others' && event['cust-adj-other-reason']) {
                            reason = event['cust-adj-other-reason'];
                        }
                        else {
                            reason = event['cust-adj-reason'];
                        }
                        return [4 /*yield*/, this.roleBasedAccess.performAdjustment(adjustmentType, CUSTOMER)];
                    case 1:
                        maxAdjAmount = _a.sent();
                        if (maxAdjAmount && event['cust-adj-amount'] && (parseInt(event['cust-adj-amount'], 10) > maxAdjAmount)) {
                            this.toasterService.showToaster(new Toaster({
                                type: ToasterType.WARNING,
                                message: 'You are allowed to  ' + adjustmentType + ' max Rs. ' + maxAdjAmount + '. Please enter less than ' + maxAdjAmount
                            }));
                            return [2 /*return*/];
                        }
                        customer = this.customerAdjDetails.customer || {};
                        adjustmentObj = {
                            actorId: userDetails['_id'],
                            actorEmail: userDetails['emailId'],
                            ownerId: this.customerAdjDetails.customerId || '',
                            ownerType: 'customer',
                            source: walletType || 'rapido',
                            remarks: reason,
                            wallet: walletType,
                            email: customer.email || '',
                            uniqueId: this.customerAdjDetails.uniqueId || '',
                            ownerName: customer.name || '',
                            ownerPhoneNumber: customer.mobile || '',
                            orderId: this.orderId,
                            ticketId: event['cust-adj-ticket']
                        };
                        customerCreatedOn = this.objectIdToEpochTimestamp(this.customerAdjDetails.customerId);
                        confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
                            width: '335px',
                            data: { title: 'Are you sure you want to  ' + event['cust-adj-action'] + ' ?',
                                type: 'order-details',
                                reason: event['cust-adj-action'],
                                message: '',
                                buttonText: 'Yes, ' + event['cust-adj-action']
                            }
                        });
                        // refund or credit
                        if (event['cust-adj-action'] === ADJUSTMENT_TYPES.REFUND || event['cust-adj-action'] === ADJUSTMENT_TYPES.CREDIT) {
                            adjustmentObj['custRefundAmount'] = event['cust-adj-amount'];
                            confirmDialogRef.afterClosed().subscribe(function (confirmResult) {
                                if (confirmResult) {
                                    _this.disableSubmit = true;
                                    if (event['cust-adj-action'] === ADJUSTMENT_TYPES.REFUND) {
                                        _this.logData = {
                                            type: 'CUSTOMER_ADJUSTMENT_ACTION_REFUND',
                                            agent: {
                                                agentId: userDetails['_id'],
                                                email: userDetails['emailId'],
                                                roles: _this.roles,
                                            },
                                            user: {
                                                userId: _this.customerAdjDetails.customerId || '',
                                                createdOn: customerCreatedOn
                                            },
                                            source: 'profiles-dashboard',
                                            metadata: adjustmentObj,
                                            eventTimeStamp: new Date().getTime(),
                                        };
                                    }
                                    else if (event['cust-adj-action'] === ADJUSTMENT_TYPES.CREDIT) {
                                        _this.logData = {
                                            type: 'CUSTOMER_ADJUSTMENT_ACTION_CREDIT',
                                            agent: {
                                                agentId: userDetails['_id'],
                                                email: userDetails['emailId'],
                                                roles: _this.roles,
                                            },
                                            user: {
                                                userId: _this.customerAdjDetails.customerId || '',
                                                createdOn: customerCreatedOn
                                            },
                                            source: 'profiles-dashboard',
                                            metadata: adjustmentObj,
                                            eventTimeStamp: new Date().getTime(),
                                        };
                                    }
                                    _this.customerAdjService.customerRefundDetails(adjustmentObj, userDetails['_id'], _this.logData).subscribe(function (response) {
                                        if (response && response['info'] && response['info'].status === 'success') {
                                            _this.toasterService.showToaster(new Toaster({
                                                type: ToasterType.SUCCESS,
                                                message: "Amount refunded successfully"
                                            }));
                                            _this.success = true;
                                            _this.disableSubmit = false;
                                            _this.checkStatus();
                                            _this.adjustmentDone.emit();
                                        }
                                    }, function (err) {
                                        _this.disableSubmit = false;
                                        _this.toasterService.showToaster(new Toaster({
                                            type: ToasterType.WARNING,
                                            message: "Unable to refund for customer",
                                        }));
                                    });
                                }
                            });
                        }
                        else {
                            adjustmentObj['customerPayableAmount'] = event['cust-adj-amount'];
                            confirmDialogRef.afterClosed().subscribe(function (confirmResult) {
                                if (confirmResult) {
                                    _this.disableSubmit = true;
                                    _this.logData = {
                                        type: 'CUSTOMER_ADJUSTMENT_ACTION_DEBIT',
                                        agent: {
                                            agentId: userDetails['_id'],
                                            email: userDetails['emailId'],
                                            roles: _this.roles,
                                        },
                                        user: {
                                            userId: _this.customerAdjDetails.customerId || '',
                                            createdOn: customerCreatedOn
                                        },
                                        source: 'profiles-dashboard',
                                        metadata: adjustmentObj,
                                        eventTimeStamp: new Date().getTime(),
                                    };
                                    _this.customerAdjService.customerDebitDetails(adjustmentObj, userDetails['_id'], _this.logData).subscribe(function (response) {
                                        if (response && response['info'] && response['info'].status === 'success') {
                                            _this.toasterService.showToaster(new Toaster({
                                                type: ToasterType.SUCCESS,
                                                message: "Amount debited successfully"
                                            }));
                                            _this.success = true;
                                            _this.disableSubmit = false;
                                            _this.checkStatus();
                                            _this.adjustmentDone.emit();
                                        }
                                    }, function (err) {
                                        if (err && err['error'] && err['error'].data) {
                                            _this.disableSubmit = false;
                                            var errorMessage = JSON.parse(err.error.data.split('-')[1]);
                                            _this.toasterService.showToaster(new Toaster({
                                                type: ToasterType.WARNING,
                                                message: errorMessage.message,
                                            }));
                                        }
                                    });
                                }
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CustAdjustmentComponent.prototype.accessCookie = function (cookieName) {
        var name = cookieName + '=';
        var allCookieArray = document.cookie.split(';');
        for (var i = 0; i < allCookieArray.length; i++) {
            if (allCookieArray[i]) {
                var cookieItem = allCookieArray[i].trim();
                if (cookieItem.indexOf(name) === 0) {
                    return cookieItem.substring(name.length, cookieItem.length);
                }
            }
        }
        return '';
    };
    CustAdjustmentComponent.prototype.objectIdToEpochTimestamp = function (objectId) {
        if (!objectId) {
            return 0;
        }
        var timestamp = parseInt(objectId.substring(0, 8), 16);
        var epochTimestamp = timestamp * 1000;
        return epochTimestamp;
    };
    return CustAdjustmentComponent;
}());
export { CustAdjustmentComponent };
