import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faFilter, faRedo } from '@fortawesome/free-solid-svg-icons';
import { CaptainLastLocationsService } from 'src/app/captain-last-locations.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { ToasterService } from 'src/app/toaster.service';
import { LocationPing } from '../../shared/types/location.types';
import { MatIcon } from '@angular/material';


@Component({
  selector: 'app-captain-last-locations',
  templateUrl: './captain-last-locations.component.html',
  styleUrls: ['./captain-last-locations.component.css']
})
export class CaptainLastLocationsComponent implements OnInit {

  @Input() captainId: string;
  @ViewChild('map') mapElement: any;

  faRefresh = faRedo;
  faFilter = faFilter;

  map: any;
  mapProperties: any;
  locationPings: any = [];
  locationPingMarkers: any[] = [];
  locationPingIcon = {
    url: '../../assets/map/actualRoute.png',
    scaledSize: new google.maps.Size(10, 10)
  };

  timestampPattern: string = '^([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$';
  defaultDate: Date = new Date(Date.now());
  locationPingInfoWindow = new google.maps.InfoWindow({});

  errorReasons = {
    DIFF_GREATER_THAN_HOUR: 'Timestamps difference exceeding 15 minutes is not allowed.',
    NO_LOCATION_PINGS: 'No location pings were found for the specified time range.',
    LOCATION_PINGS_FETCH_FAILED: 'Location pings fetch failed'
  }

  timeInputFormGroup: FormGroup

  padZero(value: number): string {
    return value < 10 ? `0${value}` : value.toString();
  }

  formatTime(date: Date): string {
    const hours = this.padZero(date.getHours());
    const minutes = this.padZero(date.getMinutes());
    const seconds = this.padZero(date.getSeconds());
    return `${hours}:${minutes}:${seconds}`;
  };

  getDefaultTimestamps() {
    const currentDate = new Date();
    const startTime = new Date(currentDate.getTime() - 15 * 59000);
    const defaultStartTime = this.formatTime(startTime);
    const defaultEndTime = this.formatTime(currentDate);
    return {
      defaultStartTime, defaultEndTime
    }
  }

  constructor(
    private toasterService: ToasterService,
    private captainLastLocationService: CaptainLastLocationsService) { }

  private initializeForm(): void {
    const { defaultEndTime, defaultStartTime } = this.getDefaultTimestamps();
    this.timeInputFormGroup = new FormGroup({
      date: new FormControl(this.defaultDate, null),
      startTime: new FormControl(defaultStartTime, [Validators.required, Validators.pattern(this.timestampPattern)]),
      endTime: new FormControl(defaultEndTime, [Validators.required, Validators.pattern(this.timestampPattern)])
    });
  }

  private initializeMap(): void {
    this.mapProperties = {
      center: new google.maps.LatLng(13.00035107483828, 77.58797505841818),
      zoom: 15,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      clickableIcons: false,
      gestureHandling: 'cooperative'
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapProperties);
  }

  ngOnInit() {
    this.initializeMap();
    this.initializeForm();
  }

  validateTimestamps(startTime, endTime) {
    return Math.abs(endTime - startTime) < 900000;
  }

  constructTimestamps() {
    const date = this.timeInputFormGroup.get("date").value;
    const enteredDate = new Date(date);
    const [startHour, startMinutes, startSeconds] = this.timeInputFormGroup.get("startTime").value.split(':');
    const [endHour, endMinutes, endSeconds] = this.timeInputFormGroup.get("endTime").value.split(':');

    const startTime = new Date(
      enteredDate.getFullYear(),
      enteredDate.getMonth(),
      enteredDate.getDate(),
      startHour,
      startMinutes,
      startSeconds
    ).getTime();

    const endTime = new Date(
      enteredDate.getFullYear(),
      enteredDate.getMonth(),
      enteredDate.getDate(),
      endHour,
      endMinutes,
      endSeconds
    ).getTime();

    return {
      startTime, endTime
    }
  }

  renderLocationMarkers(locationPings: LocationPing[]) {
    for (const locationPing of locationPings) {
      const locationPingMarker = new google.maps.Marker({
        icon: this.locationPingIcon
      });
      locationPingMarker.setPosition(locationPing.location);
      locationPingMarker.setMap(this.map);

      const timeStampContent = `
      <div style="font-family: Arial; font-size: 14px;">
      <strong>Date:</strong> ${locationPing.date.toDateString()}<br>
      <strong>Time:</strong> ${locationPing.date.toLocaleTimeString()}
      </div>
    `;
      locationPingMarker.addListener('click', (event) => {
        this.locationPingInfoWindow.setContent(timeStampContent);
        this.locationPingInfoWindow.setPosition(locationPing.location);
        this.locationPingInfoWindow.open(this.map);
      });
      this.locationPingMarkers.push(locationPingMarker);
    }
    this.map.setCenter(locationPings[0].location);
  }

  removeLocationMarkers() {
    for (const locationPingMarker of this.locationPingMarkers) {
      locationPingMarker.setMap(null);
    }
    this.locationPingMarkers = [];
  }

  resetMap() {
    this.removeLocationMarkers();
    this.map.setCenter(new google.maps.LatLng(13.00035107483828, 77.58797505841818));
    this.map.setZoom(15);
  }

  reset() {
    this.resetMap();
    const { defaultEndTime, defaultStartTime } = this.getDefaultTimestamps();
    this.timeInputFormGroup.reset({
      date: new Date(),
      startTime: defaultStartTime,
      endTime: defaultEndTime
    });
    this.locationPings = [];
    this.locationPingMarkers = [];
  }

  showToaster(message) {
    this.toasterService.showToaster(new Toaster({
      type: ToasterType.WARNING,
      message
    }));
  }

  onSubmit() {
    this.removeLocationMarkers();
    const { startTime, endTime } = this.constructTimestamps();
    if (!this.validateTimestamps(startTime, endTime)) {
      this.showToaster(this.errorReasons.DIFF_GREATER_THAN_HOUR);
      return;
    }
    this.captainLastLocationService.
      getCaptainPreviousLocationsByTimestamps(this.captainId,
        startTime, endTime).subscribe(result => {
          this.locationPings = this.captainLastLocationService.constructLocationPings(result['locations']);
          if (this.locationPings.length == 0) {
            this.showToaster(this.errorReasons.NO_LOCATION_PINGS)
          } else {
            this.renderLocationMarkers(this.locationPings)
          }
        }, error => {
          console.error(`Captain last location fetch failed`, error);
          this.showToaster(this.errorReasons.LOCATION_PINGS_FETCH_FAILED);
          return;
        });
  }
}