import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MyHttpClient } from './http-client.service';
import { HttpClient } from '@angular/common/http';
import { of, Observable, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HelperService } from './helper.service';


@Injectable()
export class EntityService {

  private cache = {};
  constructor(
    public httpClient: HttpClient
  ) {
  }

  public USER_TYPE = {
    CUSTOMER: 'CUSTOMER',
    CAPTAIN: 'RIDER',
    MANAGER: 'MANAGER',
  };

  accessCookie(cookieName) {
    const name = cookieName + '=';
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
      if (allCookieArray[i]) {
        const cookieItem = allCookieArray[i].trim();
        if (cookieItem.indexOf(name) === 0) {
          return cookieItem.substring(name.length, cookieItem.length);
        }
      }
    }
    return '';
  }

  

  getReferralDetails(userId) {
    return this.httpClient.get(`${environment.server}/api/referralDetailsV2/RIDER/${userId}`);
    // return this.httpClient.get(`${environment.server}/api/referralDetails/${userId}`);
  }
  getReferralDetailsV2(userId: string, userType: string) {
    const cookieUser = this.accessCookie('user') && JSON.parse(this.accessCookie('user')) ?
                JSON.parse(this.accessCookie('user'))['_id'] : null;
    if (cookieUser) {
      return this.httpClient.get(`${environment.server}/api/referralDetailsV2/${userType}/${userId}/${cookieUser}`);
    } else {
      return Observable.of({});
    }
  }


  getRefereeDetails(userId: string, userType: string, mobile: string){
    return this.httpClient.get(`${environment.server}/api/getRefereeDetails/${userId}/${userType}/${mobile}`);
  }
  

  getEntityByNumber(mobileNumber: string, type?: string, email?: string, agentId?: string, roles?: Array<string>) {
    const uniqueId = () => {
      return `captainDetails-${mobileNumber}`;
    };
    const cachedCaptainDetails = this.cache[uniqueId()];
    if (cachedCaptainDetails) {
      return of(cachedCaptainDetails);
    }
    const url = `${environment.server}/api/show/${mobileNumber}?type=${type}&email=${email}&agentId=${agentId}&roles=${roles}`;
    return this.httpClient.get(url);
  }

  isBlacklisted(deviceId: string, captainId: string) {
    const url = `${environment.server}/api/isBlacklisted/${deviceId}/${captainId}`;
    return this.httpClient.get(url);
  }

  unblockCaptain(deviceId: string) {
    const url = `${environment.server}/api/removeBlacklist`;
    return this.httpClient.put(url, {
      deviceIdList: [deviceId]
    });
  }

  getLatestAuthLog(userId: string) {
    const url = `${environment.server}/api/authlogs/${userId}`;
    return this.httpClient.get(url);
  }

  getCities() {
    const url = `${environment.server}/api/get/cities`;
    return this.httpClient.get(url).map((res: any) => {
      const cities = res.data.cities.map(city => {
        return {
          toString: () => {
            return city.displayName;
          },
          valueOf: () => {
            return city._id;
          }
        }
      });
      return cities;
    });
  }

  getCustomerActivationDeactivateLogs(customerId) {
    // customerId = '5c7e3c6606bf824646cf0c6d';
    const url = `${environment.server}/api/customer/${customerId}/activationDeactivateLogs`
    return this.httpClient.get(url)
      .pipe(map((activities: any) => {
        if (!(activities instanceof Array)) {
          return [];
        }
        const filteredActivity = activities.filter(activity => (
          activity.changed && activity.changed.hasOwnProperty('active') ||
          activity.type === "Login Issue"
        ));
        const formattedData = filteredActivity.map(activity => {
          let status;
          let reason;
          const ts = activity.timeStamp;
          const date = HelperService.getDateOnly(new Date(ts));
          // const ts = activity.createdOn;
          if (activity.type === "Login Issue") {
            status = activity.type
            reason = activity.metaData.reason
          } else {
            status = activity.changed.active ? 'Activated' : 'Deactivated';
            reason = activity.metadata.reason;
          }

          return {
            date: date,
            // date: activity.insertionDate,
            time: new Date(ts).toString().split(' ')[4],
            status: status,
            reason: reason,
            agentEmail: activity.actor && activity.actor.email ? activity.actor.email : '',
            agentName: activity.actor && activity.actor.name ? activity.actor.name : ''
          }
        });
        return formattedData;
      }))
  }
  
  getUserByEmail(email: string) {
    const url = `${environment.server}/api/user/email/${email}`;
    return this.httpClient.get(url);
  }

  getReferralInfo(id) {
    return this.getReferralDetailsV2(id, this.USER_TYPE.CAPTAIN)
      .map((res: any) => {
        res.referral = res.result || {};
        return [res.referral.referralCode];
      }).catch(err => {
        return Observable.of({});
      });
  }

  getServices(cities: any[]) {
    const services = cities.map(city => {
      return this.getServicesCityWise(city);
    });
    return forkJoin(...services).map(responses => {
      const services = {};
      responses.map(response => {
        response.data.map(d => {
          if (d.service && d.service._id) {
            services[d.service._id] = d.service;
          }
        });
      });
      return Object.keys(services).map(key => services[key]).map(service => {
        return {
          toString: () => {
            return service.name;
          },
        };
      });
    })
    // return of([
    //   'Bike',
    //   'Rapido-Test',
    // ]);
  }

  getAllServices() {
    const url = `${environment.server}/api/services`;
    return this.httpClient.post(url, {})
      .map(response => {
        const services = {};
        if (Array.isArray(response["data"])) {
          response["data"].map(d => services[d._id] = d);
        }
        return services
      })
  }

  getServiceDetails(riderId) {
    const url = `${environment.server}/api/rider/serviceDetails`;
    return this.httpClient
      .post(url, { riderId })
      .map((res) => {
        return res ? res : {};
      });
  }

  getServiceDetailsForRider(riderId, lat, lng) {
    const url = `${environment.server}/api/getRiderServiceDetails`;
    return this.httpClient
      .post(url, { riderId:riderId, latitude:lat, longitude:lng })
      .map((res) => {
        return res;
      });
  }

  getServiceNameDetails() {
    const url = `${environment.server}/api/service/serviceNameDetails`;
    return this.httpClient
      .post(url, {})
      .map((res) => {
        return res ? res : {};
      });
  }

  getCaptainCancellations(riderId) {
    const url = `${environment.server}/api/rider/cancellation`;
    return this.httpClient
      .post(url, { riderId })
      .map((res) => {
        return res ? res : {};
      });
  }

  getServicesPerCity(city: any) {
    const url = `${environment.server}/api/get/services`;
    return this.httpClient.post(url, {
      city: city,
    });
  }

  getServicesCityWise(city: any) {
    const url = `${environment.server}/api/get/services`;
    return this.httpClient.post(url, {
      city: city.valueOf(),
    });
  }

  getCaptainActivityLogs(captainId: any, page: number, perPage: number, condition?){
    const url = `${environment.server}/api/eventLogs/captainActivityLogs/${captainId}/${page}/${perPage}?condition=${condition}`;
    return this.httpClient.get(url);
  }
}
