import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ICON_MAPPING, NavBar, NavBarTitle, NavBarOptions, NavBarOption } from '../shared/side-nav/side-nav.component';
import { PerformanceCard } from '../shared/types/card.types';
import { Pagination, Table, ThumbnailCard, TableData, TableDataTypes } from '../shared/types';
import { ThumbnailCardTypes, RoundLevel } from '../shared/types/thumnail-card.types';
import { HorizontalList } from '../shared/horizontal-list/horizontal-list.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CaptainService } from '../captain.service';
import { RecordTable } from '../shared/types/record-table.types';
import { FilterService } from '../filter.service';
import { HelperService } from '../helper.service';
import { EntityService } from 'src/app/entity.service';



@Component({
  selector: 'app-captain-details',
  templateUrl: './captain-details.component.html',
  styleUrls: ['./captain-details.component.css']
})
export class CaptainDetailsComponent implements OnInit {
  generalInfoTable: Table;
  rapidoAccountInfoTable: Table;
  rapidoEarningInfoTable: Table;
  captainDetails: any;
  id: string; // mobilenumber
  deviceId: string;
  captainId: string;
  userId: string;
  captainLevelName: string;
  mode: string;
  captainAllRateCard: Object;
  serviceNameDetails: Object;
  liveLocation: any;
  userInfo = this.accessCookie('user');
  userDetails = JSON.parse(this.userInfo);
  roles =  this.accessCookie('Roles').split(','); 
  public toggleableDetails = 'Order Details';
  public captainDetailsToggleableHeaders: string[] = [
    'Order Details',
    'Activity Logs',
    'Bulk Action Logs',
    'Ticket Details',
    'Rapido Pay Logs',
    'Complaints By Customer',
    'Subscription Details',
    'Customer Complaints against Captain',
    'Call Logs',
    'FM Breaches',
    'Propagation Events',
    'Access Fee Transactions'
  ];

  constructor(private route: ActivatedRoute,
    private router: Router,
    public captainService: CaptainService,
    private filterService: FilterService,
    private entityService: EntityService
    ) {}

  ICON_MAPPING = ICON_MAPPING;
  dataReceived = false;
  filterComponents: any[] = [];
  showFilters = false;

  ngOnInit() {
    this.defineFilters();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    let id = this.route.snapshot.paramMap.get('id');
    if (!id) {
      id = this.route.snapshot.queryParamMap.get('phone');
    }
    if (!id) {
      return;
    }
    this.id = HelperService.decode(id);
    this.entityService.getEntityByNumber(this.id, 'SEARCH_CAPTAIN_MOBILE', this.userDetails['emailId'], this.userDetails['_id'], this.roles).subscribe(data => {
      this.captainDetails = data;
      this.deviceId = data.device && data.device.deviceId;
      this.captainId = data && data['userId'] && data['userId']['_id'];
      this.userId = data && data['userId'] && data['userId']['_id'] || '';
      this.mode = data.modeId && data.modeId.mode;
      this.captainService.captainLiveStat(this.captainId)
        .toPromise()
        .then((data: any) => {
          this.liveLocation = data.riderLocation && data.riderLocation.location;
        })
        .finally(() => {
          let lat: any, lng: any;
          if (this.liveLocation) {
            [lng, lat] = this.liveLocation;
          }
          this.captainService.getCaptainAllRateCards(this.userId, lat, lng).subscribe(rateCardData => {
            if (Object.keys(rateCardData).length) {
              this.captainAllRateCard = rateCardData;
            }
          });
        });
    });
  }

  captainLevelDisplayName(name) {
    this.captainLevelName = name;
  }

  headersToggled(toggledHeader) {
    this.toggleableDetails = toggledHeader;
  }

  defineFilters() {
    this.filterComponents = [
      {
        type: 'searchable-dropdown',
        title: 'City',
        values: ['a', 'b', 'c'],
        default: '',
        key: 'incentive-city'
      },
      {
        type: 'searchable-dropdown',
        title: 'Services',
        values: ['a', 'b', 'c'],
        default: '',
        key: 'incentive-services'
      },
      {
        type : 'calendar',
        title : '',
        values : [],
        default : '',
        key : 'incentive-dates'
      },
      {
        type : 'radio-button',
        title : 'Status',
        values : ['Both', 'Active', 'Inactive'],
        default : '',
        key: 'incentive-status'
      }
    ];

    this.dataReceived = true;
  }
  // filterToggle() {
  //   this.showFilters = !this.showFilters;
  // }
  listen(events) {
    // console.log(events);
  }
  clearFilters() {
    this.dataReceived = false;
    setTimeout (() => {
      this.dataReceived = true;
   }, 200);
  }
  applyFilters(event) {
    // console.log(event);
  }

  accessCookie(cookieName) {
    const name = cookieName + '=';
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
      if (allCookieArray[i]) {
        const cookieItem = allCookieArray[i].trim();
        if (cookieItem.indexOf(name) === 0) {
          return cookieItem.substring(name.length, cookieItem.length);
        }
      }
    }
    return '';
  }

}
