<app-page-header title="Captain details{{ captainLevelName ? ' (Level :' + captainLevelName + ')' : ''}}">
</app-page-header>
<div class="row row-cards" style="padding-bottom: 15px;">
  <div *ngIf = "captainDetails" class="col-md-3">
    <app-captain-general-info [captainDetails]="captainDetails" [mobile]="id" [device]="deviceId"></app-captain-general-info>
  </div>
  <div *ngIf = "id" class="col-md-3">
    <app-captain-rapido-account [captainId]="id"></app-captain-rapido-account>
  </div>
  <div *ngIf = "id && captainDetails" class="col-md-3">
    <app-captain-earnings [captainDetails]="captainDetails" [captainId]="captainId" [mobile]="id"></app-captain-earnings>
  </div>
  <div *ngIf = "id && captainAllRateCard" class="col-md-3" >
    <app-captain-rate-card [rateCardInfo]="captainAllRateCard"></app-captain-rate-card>
  </div>
</div>
<app-captain-date-wise-stat-details *ngIf = "captainId" [captainId]="captainId" [captainMobile]="id"></app-captain-date-wise-stat-details>
<app-captain-performance-data *ngIf="userId" [riderId]="userId"> </app-captain-performance-data>
<app-single-view-of-captain *ngIf="userId" [riderId]="userId" (captainLevelDisplayName)="captainLevelDisplayName($event)" [captainId]="id">
</app-single-view-of-captain>
<app-hand-holding-details [captainId]="captainId"></app-hand-holding-details>
<app-captain-current-stat-details [captainId]="id"></app-captain-current-stat-details>
<app-captain-last-locations class="padding-30" [captainId]="captainId"></app-captain-last-locations>
<div _ngcontent-c11="" class="height-15"></div>
<div class="row row-cards">
    <div class="col-md-12" style="
    margin-bottom: 24px;">
    <app-captain-order-details [userId]="userId" [captainObjectId]="captainId" [captainId]="id" [captainDetailsToggleableHeaders]="captainDetailsToggleableHeaders" *ngIf="toggleableDetails === 'Order Details' && captainId && userId"
      (toggleHeaders)="headersToggled($event)"></app-captain-order-details>
    <app-captain-activities [riderId]="userId" [captainId]="id" [captainDetailsToggleableHeaders]="captainDetailsToggleableHeaders" (toggleHeaders)="headersToggled($event)"
      *ngIf="toggleableDetails === 'Activity Logs' && userId"></app-captain-activities>
    <app-captain-bulk-action [riderId]="userId" [captainId]="id" [captainDetailsToggleableHeaders]="captainDetailsToggleableHeaders" (toggleHeaders)="headersToggled($event)"
      *ngIf="toggleableDetails === 'Bulk Action Logs' && userId"></app-captain-bulk-action>
    <app-captain-tickets [captainId]="captainId" [mobile]="id" [captainDetailsToggleableHeaders]="captainDetailsToggleableHeaders" (toggleHeaders)="headersToggled($event)"
      *ngIf="toggleableDetails === 'Ticket Details'"></app-captain-tickets>
    <app-captain-rapidoPay-logs [riderId]="userId" [captainDetailsToggleableHeaders]="captainDetailsToggleableHeaders" (toggleHeaders)="headersToggled($event)"
      *ngIf="toggleableDetails === 'Rapido Pay Logs' && userId"></app-captain-rapidoPay-logs>
    <app-captain-complaints-by-customer [captainId]="captainId" [captainDetailsToggleableHeaders]="captainDetailsToggleableHeaders" (toggleHeaders)="headersToggled($event)"
      *ngIf="toggleableDetails === 'Complaints By Customer'"></app-captain-complaints-by-customer>
    <app-captain-subscriptions [captainId]="captainId" [captainDetailsToggleableHeaders]="captainDetailsToggleableHeaders" (toggleHeaders)="headersToggled($event)"
        *ngIf="toggleableDetails === 'Subscription Details'"></app-captain-subscriptions>
    <app-tickets-against-captain [captainId]="captainId" [captainDetailsToggleableHeaders]="captainDetailsToggleableHeaders" (toggleHeaders)="headersToggled($event)"
        *ngIf="toggleableDetails === 'Customer Complaints against Captain'"></app-tickets-against-captain>
    <app-captain-call-logs [captainId]="captainId" [captainDetailsToggleableHeaders]="captainDetailsToggleableHeaders" (toggleHeaders)="headersToggled($event)"
                               *ngIf="toggleableDetails === 'Call Logs'"></app-captain-call-logs>
    <app-captain-propagation-breaches [userId]="userId" [captainObjectId]="captainId" [captainId]="id" [captainDetailsToggleableHeaders]="captainDetailsToggleableHeaders"
                                      *ngIf="toggleableDetails === 'FM Breaches'" (toggleHeaders)="headersToggled($event)"></app-captain-propagation-breaches>
    <app-captain-propagation-events [captainObjectId]="captainId" [captainDetailsToggleableHeaders]="captainDetailsToggleableHeaders"
                                     *ngIf="toggleableDetails === 'Propagation Events'" (toggleHeaders)="headersToggled($event)"></app-captain-propagation-events>
    <app-captain-accessFee-details [captainId]="captainId" [captainDetailsToggleableHeaders]="captainDetailsToggleableHeaders"
    *ngIf="toggleableDetails === 'Access Fee Transactions'" (toggleHeaders)="headersToggled($event)"></app-captain-accessFee-details>
                                     
  </div>
</div>
<app-captain-incentive-progress *ngIf = "userId" class="padding-bottom-15" [riderId]="userId" [riderMobile]="id"></app-captain-incentive-progress>

