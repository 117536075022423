import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Pagination } from './shared/types';
import { ORDERS_QOS, ORDER_STATUS_TO_EVENTNAME_MAPPING } from './shared/constants';
export const ENTITY_TYPES = {
  CAPTAIN: 'captain',
  CUSTOMER: 'customer',
};
@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    public httpClient: HttpClient,
  ) { }

  getOrdersForUser(userId: string, pagination: Pagination, fromDate?: Date, toDate?: Date, type = ENTITY_TYPES.CAPTAIN, appliedFilters: any = {}) {
    const DEFAULT_TEXT = ' - ';
    const url = `${environment.server}/api/v2/orders/fetch`;

    let filters = {}

    if (appliedFilters.startDuration && appliedFilters.endDuration) {
      let { startDate, endDate } = formatDurationForQuery({
        fromDate: appliedFilters.startDuration,
        toDate: appliedFilters.endDuration
      });
      filters = {
        startDate,
        endDate,
      }
    } else if (fromDate && toDate) {
      let startDate, endDate;
      fromDate = new Date(fromDate);
      toDate = new Date(toDate);
      startDate = orderDateFormat(fromDate);
      endDate = orderDateFormat(toDate);
      filters = {
        startDate,
        endDate,
      }
    }

    if (appliedFilters.cities.length > 0) {
      let cities = appliedFilters.cities.join(',')
      let citiesArray = cities.split(',')
      filters['cityNames'] = citiesArray
    }

    if (appliedFilters.services.length > 0) {
      let services = appliedFilters.services.join(',')
      let servicesArray = services.split(',')
      filters['serviceNames'] = servicesArray
    }

    if (appliedFilters.status != 'all') {
      filters['status'] = appliedFilters.status
    }

    switch (type) {
      case ENTITY_TYPES.CAPTAIN: {
        filters['captainId'] = userId
        break;
      }
      case ENTITY_TYPES.CUSTOMER: {
        filters['customerId'] = userId;
        break;
      }
    }

    let requestBody = {
      filters,
      responseParams: {
        fetchAllFields: true,
        fetchTotalCount: true,
        sortOption: {
          type: 'timeOfEventCreation',
          desc: true
        }
      },
      limit: pagination.perPage,
      offset: pagination.perPage * (pagination.pageNo),
      qos: ORDERS_QOS.qos2
    }

    function formatDurationForQuery({
      fromDate,
      toDate,
    }) {
      fromDate = new Date(fromDate);
      toDate = new Date(toDate);
      const startDate = new Date(fromDate);
      const endDate = new Date(toDate);
      return {
        startDate : orderDateFormat(startDate),
        endDate : orderDateFormat(endDate)
      };
    }

    function orderDateFormat(date: Date) {
      function padStart(length, data) {
        return data.toString().padStart(length, 0)
      }
      const dateString = `${date.getFullYear()}-${padStart(2, date.getMonth()+1)}-${padStart(2, date.getDate())}`;
      return dateString;
    }

    function getCancelReason(events) {
      let cancelReason;
      for (let i = events.length - 1; i >= 0; i--) {
        let event = events[i];
        if (event.eventName === 'customer_cancelled') {
          cancelReason = event.cancelReason ? event.cancelReason : event.otherReason ? event.otherReason : null;
          break;
        }
      }
      return cancelReason;
    }

    return this.httpClient.post(`${url}`, requestBody).map((res: any) => {
      const count = res.totalOrders;
      const orders = res.orders || [];
      const mappedOrders = orders.map(order => {
        const serviceDetail = order.serviceDetail || {};
        const customer = order.customer || {};
        const pricing = order.pricing || {}
        const pickupLocation = order.pickupLocation || {};
        const dropLocation = order.dropLocation || {};
        function pad2(data) {
          return data.toString().padStart(2,0)
        }
        function getHourseMinutes(date) {
          try {

            date = new Date(date);
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0'+ minutes : minutes;
            let strTime = pad2(hours) + ':' + pad2(minutes) + ' ' + ampm;
            return strTime;
          } catch(err) {
            console.error(err);
            return '';
          }
        }
        const orderTime = getHourseMinutes(order.createdOn);
        const cancelReason = getCancelReason(order.events)
        return {
          date: order.orderTime && order.orderTime.date ? new Date(order.orderTime.date) : '',
          orderTime,
          orderId: order.uniqueId,
          service: serviceDetail.service && serviceDetail.service.name || DEFAULT_TEXT,
          rideTime: pricing.rideTime,
          city: serviceDetail.city && serviceDetail.city.displayName || DEFAULT_TEXT,
          pickup: pickupLocation.address,
          drop: dropLocation.address,
          customer: customer.name,
          earnings: pricing.amount,
          customerRating: order.feedback && order.feedback.customer && order.feedback.customer.rating || DEFAULT_TEXT,
          riderRating: order.feedback && order.feedback.captain && order.feedback.captain.rating || DEFAULT_TEXT,
          offerType: pricing.offer && pricing.offer.type || DEFAULT_TEXT,
          discount: pricing.discount,
          cashback: pricing.cashBack && pricing.cashBack[0] && pricing.cashBack[0].cashBackAmount || 0,
          status: order.status,
          couponCode: pricing.couponCode,
          couponApplied: pricing.couponCode && pricing.couponCode != '',
          offerApplied: pricing.offer && pricing.offer.applied,
          offerCode: pricing.offer && pricing.offer.code || DEFAULT_TEXT,
          cancelReason: cancelReason,
          riderName: order.captain && order.captain.name || DEFAULT_TEXT,
          _id: order._id
        };
      });
      return {
        orders: mappedOrders,
        count,
      };
    }).pipe(catchError(this.handleError));
  }

  getOrdersForCaptain(userId: string, pagination: Pagination, fromDate?: Date, toDate?: Date, type = ENTITY_TYPES.CAPTAIN, appliedFilters: any = {}) {
    const DEFAULT_TEXT = ' - ';
    const url = `${environment.server}/api/v2/orders/captain/order-events`;

    let filters = {}

    if (appliedFilters.startDuration && appliedFilters.endDuration) {
      let { startDate, endDate } = formatDurationForQuery({
        fromDate: appliedFilters.startDuration,
        toDate: appliedFilters.endDuration
      });
      filters = {
        startDate,
        endDate,
      }
    } else if (fromDate && toDate) {
      let startDate, endDate;
      fromDate = new Date(fromDate);
      toDate = new Date(toDate);
      startDate = orderDateFormat(fromDate);
      endDate = orderDateFormat(toDate);
      filters = {
        startDate,
        endDate,
      }
    }



    if (appliedFilters.cities && appliedFilters.cities.length > 0) {
      let cities = appliedFilters.cities.join(',')
      let citiesArray = cities.split(',')
      filters['cityNames'] = citiesArray
    }

    if (appliedFilters.services && appliedFilters.services.length > 0) {
      let services = appliedFilters.services.join(',')
      let servicesArray = services.split(',')
      filters['serviceNames'] = servicesArray
    }

    if (appliedFilters.status && appliedFilters.status != 'all') { 
      filters['eventNames'] = [ORDER_STATUS_TO_EVENTNAME_MAPPING[appliedFilters.status]] || [appliedFilters.status]
    } else {
      filters['eventNames'] = ['accepted', 'arrived', 'started', 'reached', 'dropped', 'aborted', 'customer_cancelled', 'captain_cancelled']
    }

    switch (type) {
      case ENTITY_TYPES.CAPTAIN: {
        filters['captainId'] = userId
        break;
      }
      case ENTITY_TYPES.CUSTOMER: {
        filters['customerId'] = userId;
        break;
      }
    }

    let requestBody = {
      filters,
      responseParams: {
        fetchAllFields: true,
        fetchTotalCount: true,
        sortOption: {
          type: 'timeOfEventCreation',
          desc: true
        }
      },
      limit: pagination.perPage,
      offset: pagination.perPage * (pagination.pageNo),
      qos: ORDERS_QOS.qos2
    }

    function formatDurationForQuery({
      fromDate,
      toDate,
    }) {
      fromDate = new Date(fromDate);
      toDate = new Date(toDate);
      const startDate = new Date(fromDate);
      const endDate = new Date(toDate);
      return {
        startDate : orderDateFormat(startDate),
        endDate : orderDateFormat(endDate)
      };
    }

    function orderDateFormat(date: Date) {
      function padStart(length, data) {
        return data.toString().padStart(length, 0)
      }
      const dateString = `${date.getFullYear()}-${padStart(2, date.getMonth()+1)}-${padStart(2, date.getDate())}`;
      return dateString;
    }

    function getCancelReason(events) {
      let cancelReason;
      for (let i = events.length - 1; i >= 0; i--) {
        let event = events[i];
        if (event.eventName === 'customer_cancelled' && event.captainId === userId) {
          cancelReason = event.cancelReason ? event.cancelReason : event.otherReason ? event.otherReason : null;
          break;
        }
      }
      return cancelReason;
    }

    return this.httpClient.post(`${url}`, requestBody).map((res: any) => {
      const count = res.totalOrders;
      const orders = res.orders || [];
      const propagationMetricEvents = res.propagationMetricEvents || []
      const mappedOrders = orders.map(order => {
        const serviceDetail = order.serviceDetail || {};
        const customer = order.customer || {};
        const pricing = order.pricing || {}
        const pickupLocation = order.pickupLocation || {};
        const dropLocation = order.dropLocation || {};
        function pad2(data) {
          return data.toString().padStart(2,0)
        }
        function getHourseMinutes(date) {
          try {

            date = new Date(date);
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0'+ minutes : minutes;
            let strTime = pad2(hours) + ':' + pad2(minutes) + ' ' + ampm;
            return strTime;
          } catch(err) {
            console.error(err);
            return '';
          }
        }
        const orderTime = getHourseMinutes(order.createdOn);
        const cancelReason = getCancelReason(order.events)
        let customerRating, captainRating;
        if (order.status == 'dropped') {
          customerRating = order.feedback && order.feedback.customer && order.feedback.customer.rating || DEFAULT_TEXT
          captainRating = order.feedback && order.feedback.captain && order.feedback.captain.rating || DEFAULT_TEXT
        }
        return {
          date: order.orderTime && order.orderTime.date ? new Date(order.orderTime.date) : '',
          orderTime,
          orderId: order.uniqueId,
          service: serviceDetail.service && serviceDetail.service.name || DEFAULT_TEXT,
          rideTime: pricing.rideTime,
          city: serviceDetail.city && serviceDetail.city.displayName || DEFAULT_TEXT,
          pickup: pickupLocation.address,
          drop: dropLocation.address,
          customer: customer.name,
          earnings: pricing.amount,
          customerRating: customerRating || DEFAULT_TEXT,
          riderRating: captainRating || DEFAULT_TEXT,
          offerType: pricing.offer && pricing.offer.type || DEFAULT_TEXT,
          discount: pricing.discount,
          cashback: pricing.cashBack && pricing.cashBack[0] && pricing.cashBack[0].cashBackAmount || 0,
          status: order.status,
          couponCode: pricing.couponCode,
          couponApplied: pricing.couponCode && pricing.couponCode != '',
          offerApplied: pricing.offer && pricing.offer.applied,
          offerCode: pricing.offer && pricing.offer.code || DEFAULT_TEXT,
          cancelReason: cancelReason,
          riderName: order.captain && order.captain.name || DEFAULT_TEXT,
          _id: order._id
        };
      });
      return {
        orders: mappedOrders,
        count,
        propagationMetricEvents
      };
    }).pipe(catchError(this.handleError));
  }

  getOrders(payload) {
    let limit = payload.limit || 1
    let offset = payload.offset || 0
    let qos = payload.qos || ORDERS_QOS.qos2
    const url = `${environment.server}/api/v2/orders/fetch`;
    let requestBody = {
      filters: payload.searchConditions,
      responseParams: {
        fetchAllFields: false,
        requiredFields: payload.requiredFields
      },
      limit: limit,
      offset: offset,
      qos: qos
    }
    return this.httpClient.post(`${url}`, requestBody).pipe(catchError(this.handleError));
  }

  getPropagationMetricEventsForCaptain(userId: string, eventName: string, pagination: Pagination, type = ENTITY_TYPES.CAPTAIN) {
    const url = `${environment.server}/api/v2/orders/captain/propagation-metric-events`;
    let requestBody = {
      filters: {
        captainId: userId,
        eventName: eventName,
      },
      limit: pagination.perPage,
      offset: pagination.perPage * (pagination.pageNo),
      qos: ORDERS_QOS.qos2
    }

    return this.httpClient.post(`${url}`, requestBody).map((res: any) => {
      const count = res.count;
      const events = res.propagationMetricEvents || []
      return {
        count,
        events
      };
    }).pipe(catchError(this.handleError));
  }

getPropagationEventsForCaptain(userId: string, pagination: Pagination, startDate: string, endDate: string) {
    const url = `${environment.server}/api/v2/orders/captain/propagation-events`;
    let requestBody = {
      filters: {
        captainId: userId,
        startDate: startDate,
        endDate: endDate
      },
      limit: pagination.perPage,
      offset: pagination.perPage * pagination.pageNo,
      responseParams: {
        fetchAllFields: true,
        sortOption: {
          type: 'timeOfEventCreation',
          desc: true
        }
      },
    };
    return this.httpClient.post(`${url}`, requestBody).map((res: any) => {
      const count = res.totalRecords;
      const events = res.events || []
      return {
        count,
        events
      };
    }).pipe(catchError(this.handleError));
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}

