import { Component, OnInit } from '@angular/core';
import { SingleSearchableDropdown } from '../filter.service';
import { HelperService } from '../helper.service';
import { OrderService } from '../../app/order-service.service';
import { Router } from '@angular/router';
import { ToasterService } from '../toaster.service';
import { Toaster, ToasterType } from '../shared/types';
import { LoaderService } from '../loader.service';
import { ORDERS_QOS } from '../shared/constants';
const SEARCH_BY = {
  ORDER_ID: 'orderId',
  MOBILE: 'mobile',
  EMAIL: 'email',
};

@Component({
  selector: 'app-order-search',
  templateUrl: './order-search.component.html',
  styleUrls: ['./order-search.component.css'],
})
export class OrderSearchComponent implements OnInit {
  searchKeyword: string;
  placeholder: string = 'Search By OrderId';
  searchBy: string;
  focus = false;
  enteredValue = '';
  SEARCH_BY = SEARCH_BY;
  public filterTypeSelect = new SingleSearchableDropdown({
    key: 'value',
    title: '',
    values: [
      // SEARCH_BY.MOBILE,
      // SEARCH_BY.EMAIL,
      SEARCH_BY.ORDER_ID,
    ],
    default: SEARCH_BY.MOBILE,
    onChange: (event) => {
      console.log(event);
      const filterType = event.value;
      this.searchBy = filterType;
    }
  })
  constructor(
    private router: Router,
    private toasterService: ToasterService,
    private orderService: OrderService,
    private loaderService: LoaderService,
  ) { }

  ngOnInit() {
  }

  private notFoundError() {
    this.toasterService.showToaster(new Toaster({
      type: ToasterType.WARNING,
      message: `Not Found`,
    }));
  }

  private showError(message: string) {
    this.toasterService.showToaster(new Toaster({
      type: ToasterType.WARNING,
      message: message,
    }));
  }

  private showLoading() {
    this.toasterService.showToaster(new Toaster({
      type: ToasterType.SUCCESS,
      message: `Loading`,
    }));
  }

  async search() {
    try {
      this.loaderService.openLoading();
      const orderDate = await HelperService.getOrderDateyUniqueId(this.searchKeyword);
      if (!orderDate) {
        // this.showError("Invalid Order ID");
        throw "Invalid Order ID";
      }
      const payload = {
        'limit': 1,
        'searchConditions': {
          'uniqueId': this.searchKeyword,
          orderDate: orderDate,
        },
        'requiredFields': ['uniqueId'],
        'qos': ORDERS_QOS.qos1
      }
      let res: any;
      this.showLoading();
      res = await this.orderService.getOrders(payload).toPromise();
      if (!(res && res.orders && res.orders.length && res.orders[0] && res.orders[0]._id)) {
        throw "Order Not Found";
      }
      const orderId = res.orders[0] && res.orders[0]._id;
      document.location.href = `order/${orderId}`;
    } catch (err) {
      if (typeof err === "string") {
        this.showError(err);
        return;
      }
      this.notFoundError();
    } finally {
      this.loaderService.closeLoading();
    }
  }
}
