import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ORDERS_QOS, ORDER_STATUS_TO_EVENTNAME_MAPPING } from './shared/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export var ENTITY_TYPES = {
    CAPTAIN: 'captain',
    CUSTOMER: 'customer',
};
var OrderService = /** @class */ (function () {
    function OrderService(httpClient) {
        this.httpClient = httpClient;
    }
    OrderService.prototype.getOrdersForUser = function (userId, pagination, fromDate, toDate, type, appliedFilters) {
        if (type === void 0) { type = ENTITY_TYPES.CAPTAIN; }
        if (appliedFilters === void 0) { appliedFilters = {}; }
        var DEFAULT_TEXT = ' - ';
        var url = environment.server + "/api/v2/orders/fetch";
        var filters = {};
        if (appliedFilters.startDuration && appliedFilters.endDuration) {
            var _a = formatDurationForQuery({
                fromDate: appliedFilters.startDuration,
                toDate: appliedFilters.endDuration
            }), startDate = _a.startDate, endDate = _a.endDate;
            filters = {
                startDate: startDate,
                endDate: endDate,
            };
        }
        else if (fromDate && toDate) {
            var startDate = void 0, endDate = void 0;
            fromDate = new Date(fromDate);
            toDate = new Date(toDate);
            startDate = orderDateFormat(fromDate);
            endDate = orderDateFormat(toDate);
            filters = {
                startDate: startDate,
                endDate: endDate,
            };
        }
        if (appliedFilters.cities.length > 0) {
            var cities = appliedFilters.cities.join(',');
            var citiesArray = cities.split(',');
            filters['cityNames'] = citiesArray;
        }
        if (appliedFilters.services.length > 0) {
            var services = appliedFilters.services.join(',');
            var servicesArray = services.split(',');
            filters['serviceNames'] = servicesArray;
        }
        if (appliedFilters.status != 'all') {
            filters['status'] = appliedFilters.status;
        }
        switch (type) {
            case ENTITY_TYPES.CAPTAIN: {
                filters['captainId'] = userId;
                break;
            }
            case ENTITY_TYPES.CUSTOMER: {
                filters['customerId'] = userId;
                break;
            }
        }
        var requestBody = {
            filters: filters,
            responseParams: {
                fetchAllFields: true,
                fetchTotalCount: true,
                sortOption: {
                    type: 'timeOfEventCreation',
                    desc: true
                }
            },
            limit: pagination.perPage,
            offset: pagination.perPage * (pagination.pageNo),
            qos: ORDERS_QOS.qos2
        };
        function formatDurationForQuery(_a) {
            var fromDate = _a.fromDate, toDate = _a.toDate;
            fromDate = new Date(fromDate);
            toDate = new Date(toDate);
            var startDate = new Date(fromDate);
            var endDate = new Date(toDate);
            return {
                startDate: orderDateFormat(startDate),
                endDate: orderDateFormat(endDate)
            };
        }
        function orderDateFormat(date) {
            function padStart(length, data) {
                return data.toString().padStart(length, 0);
            }
            var dateString = date.getFullYear() + "-" + padStart(2, date.getMonth() + 1) + "-" + padStart(2, date.getDate());
            return dateString;
        }
        function getCancelReason(events) {
            var cancelReason;
            for (var i = events.length - 1; i >= 0; i--) {
                var event_1 = events[i];
                if (event_1.eventName === 'customer_cancelled') {
                    cancelReason = event_1.cancelReason ? event_1.cancelReason : event_1.otherReason ? event_1.otherReason : null;
                    break;
                }
            }
            return cancelReason;
        }
        return this.httpClient.post("" + url, requestBody).map(function (res) {
            var count = res.totalOrders;
            var orders = res.orders || [];
            var mappedOrders = orders.map(function (order) {
                var serviceDetail = order.serviceDetail || {};
                var customer = order.customer || {};
                var pricing = order.pricing || {};
                var pickupLocation = order.pickupLocation || {};
                var dropLocation = order.dropLocation || {};
                function pad2(data) {
                    return data.toString().padStart(2, 0);
                }
                function getHourseMinutes(date) {
                    try {
                        date = new Date(date);
                        var hours = date.getHours();
                        var minutes = date.getMinutes();
                        var ampm = hours >= 12 ? 'pm' : 'am';
                        hours = hours % 12;
                        hours = hours ? hours : 12; // the hour '0' should be '12'
                        minutes = minutes < 10 ? '0' + minutes : minutes;
                        var strTime = pad2(hours) + ':' + pad2(minutes) + ' ' + ampm;
                        return strTime;
                    }
                    catch (err) {
                        console.error(err);
                        return '';
                    }
                }
                var orderTime = getHourseMinutes(order.createdOn);
                var cancelReason = getCancelReason(order.events);
                return {
                    date: order.orderTime && order.orderTime.date ? new Date(order.orderTime.date) : '',
                    orderTime: orderTime,
                    orderId: order.uniqueId,
                    service: serviceDetail.service && serviceDetail.service.name || DEFAULT_TEXT,
                    rideTime: pricing.rideTime,
                    city: serviceDetail.city && serviceDetail.city.displayName || DEFAULT_TEXT,
                    pickup: pickupLocation.address,
                    drop: dropLocation.address,
                    customer: customer.name,
                    earnings: pricing.amount,
                    customerRating: order.feedback && order.feedback.customer && order.feedback.customer.rating || DEFAULT_TEXT,
                    riderRating: order.feedback && order.feedback.captain && order.feedback.captain.rating || DEFAULT_TEXT,
                    offerType: pricing.offer && pricing.offer.type || DEFAULT_TEXT,
                    discount: pricing.discount,
                    cashback: pricing.cashBack && pricing.cashBack[0] && pricing.cashBack[0].cashBackAmount || 0,
                    status: order.status,
                    couponCode: pricing.couponCode,
                    couponApplied: pricing.couponCode && pricing.couponCode != '',
                    offerApplied: pricing.offer && pricing.offer.applied,
                    offerCode: pricing.offer && pricing.offer.code || DEFAULT_TEXT,
                    cancelReason: cancelReason,
                    riderName: order.captain && order.captain.name || DEFAULT_TEXT,
                    _id: order._id
                };
            });
            return {
                orders: mappedOrders,
                count: count,
            };
        }).pipe(catchError(this.handleError));
    };
    OrderService.prototype.getOrdersForCaptain = function (userId, pagination, fromDate, toDate, type, appliedFilters) {
        if (type === void 0) { type = ENTITY_TYPES.CAPTAIN; }
        if (appliedFilters === void 0) { appliedFilters = {}; }
        var DEFAULT_TEXT = ' - ';
        var url = environment.server + "/api/v2/orders/captain/order-events";
        var filters = {};
        if (appliedFilters.startDuration && appliedFilters.endDuration) {
            var _a = formatDurationForQuery({
                fromDate: appliedFilters.startDuration,
                toDate: appliedFilters.endDuration
            }), startDate = _a.startDate, endDate = _a.endDate;
            filters = {
                startDate: startDate,
                endDate: endDate,
            };
        }
        else if (fromDate && toDate) {
            var startDate = void 0, endDate = void 0;
            fromDate = new Date(fromDate);
            toDate = new Date(toDate);
            startDate = orderDateFormat(fromDate);
            endDate = orderDateFormat(toDate);
            filters = {
                startDate: startDate,
                endDate: endDate,
            };
        }
        if (appliedFilters.cities && appliedFilters.cities.length > 0) {
            var cities = appliedFilters.cities.join(',');
            var citiesArray = cities.split(',');
            filters['cityNames'] = citiesArray;
        }
        if (appliedFilters.services && appliedFilters.services.length > 0) {
            var services = appliedFilters.services.join(',');
            var servicesArray = services.split(',');
            filters['serviceNames'] = servicesArray;
        }
        if (appliedFilters.status && appliedFilters.status != 'all') {
            filters['eventNames'] = [ORDER_STATUS_TO_EVENTNAME_MAPPING[appliedFilters.status]] || [appliedFilters.status];
        }
        else {
            filters['eventNames'] = ['accepted', 'arrived', 'started', 'reached', 'dropped', 'aborted', 'customer_cancelled', 'captain_cancelled'];
        }
        switch (type) {
            case ENTITY_TYPES.CAPTAIN: {
                filters['captainId'] = userId;
                break;
            }
            case ENTITY_TYPES.CUSTOMER: {
                filters['customerId'] = userId;
                break;
            }
        }
        var requestBody = {
            filters: filters,
            responseParams: {
                fetchAllFields: true,
                fetchTotalCount: true,
                sortOption: {
                    type: 'timeOfEventCreation',
                    desc: true
                }
            },
            limit: pagination.perPage,
            offset: pagination.perPage * (pagination.pageNo),
            qos: ORDERS_QOS.qos2
        };
        function formatDurationForQuery(_a) {
            var fromDate = _a.fromDate, toDate = _a.toDate;
            fromDate = new Date(fromDate);
            toDate = new Date(toDate);
            var startDate = new Date(fromDate);
            var endDate = new Date(toDate);
            return {
                startDate: orderDateFormat(startDate),
                endDate: orderDateFormat(endDate)
            };
        }
        function orderDateFormat(date) {
            function padStart(length, data) {
                return data.toString().padStart(length, 0);
            }
            var dateString = date.getFullYear() + "-" + padStart(2, date.getMonth() + 1) + "-" + padStart(2, date.getDate());
            return dateString;
        }
        function getCancelReason(events) {
            var cancelReason;
            for (var i = events.length - 1; i >= 0; i--) {
                var event_2 = events[i];
                if (event_2.eventName === 'customer_cancelled' && event_2.captainId === userId) {
                    cancelReason = event_2.cancelReason ? event_2.cancelReason : event_2.otherReason ? event_2.otherReason : null;
                    break;
                }
            }
            return cancelReason;
        }
        return this.httpClient.post("" + url, requestBody).map(function (res) {
            var count = res.totalOrders;
            var orders = res.orders || [];
            var propagationMetricEvents = res.propagationMetricEvents || [];
            var mappedOrders = orders.map(function (order) {
                var serviceDetail = order.serviceDetail || {};
                var customer = order.customer || {};
                var pricing = order.pricing || {};
                var pickupLocation = order.pickupLocation || {};
                var dropLocation = order.dropLocation || {};
                function pad2(data) {
                    return data.toString().padStart(2, 0);
                }
                function getHourseMinutes(date) {
                    try {
                        date = new Date(date);
                        var hours = date.getHours();
                        var minutes = date.getMinutes();
                        var ampm = hours >= 12 ? 'pm' : 'am';
                        hours = hours % 12;
                        hours = hours ? hours : 12; // the hour '0' should be '12'
                        minutes = minutes < 10 ? '0' + minutes : minutes;
                        var strTime = pad2(hours) + ':' + pad2(minutes) + ' ' + ampm;
                        return strTime;
                    }
                    catch (err) {
                        console.error(err);
                        return '';
                    }
                }
                var orderTime = getHourseMinutes(order.createdOn);
                var cancelReason = getCancelReason(order.events);
                var customerRating, captainRating;
                if (order.status == 'dropped') {
                    customerRating = order.feedback && order.feedback.customer && order.feedback.customer.rating || DEFAULT_TEXT;
                    captainRating = order.feedback && order.feedback.captain && order.feedback.captain.rating || DEFAULT_TEXT;
                }
                return {
                    date: order.orderTime && order.orderTime.date ? new Date(order.orderTime.date) : '',
                    orderTime: orderTime,
                    orderId: order.uniqueId,
                    service: serviceDetail.service && serviceDetail.service.name || DEFAULT_TEXT,
                    rideTime: pricing.rideTime,
                    city: serviceDetail.city && serviceDetail.city.displayName || DEFAULT_TEXT,
                    pickup: pickupLocation.address,
                    drop: dropLocation.address,
                    customer: customer.name,
                    earnings: pricing.amount,
                    customerRating: customerRating || DEFAULT_TEXT,
                    riderRating: captainRating || DEFAULT_TEXT,
                    offerType: pricing.offer && pricing.offer.type || DEFAULT_TEXT,
                    discount: pricing.discount,
                    cashback: pricing.cashBack && pricing.cashBack[0] && pricing.cashBack[0].cashBackAmount || 0,
                    status: order.status,
                    couponCode: pricing.couponCode,
                    couponApplied: pricing.couponCode && pricing.couponCode != '',
                    offerApplied: pricing.offer && pricing.offer.applied,
                    offerCode: pricing.offer && pricing.offer.code || DEFAULT_TEXT,
                    cancelReason: cancelReason,
                    riderName: order.captain && order.captain.name || DEFAULT_TEXT,
                    _id: order._id
                };
            });
            return {
                orders: mappedOrders,
                count: count,
                propagationMetricEvents: propagationMetricEvents
            };
        }).pipe(catchError(this.handleError));
    };
    OrderService.prototype.getOrders = function (payload) {
        var limit = payload.limit || 1;
        var offset = payload.offset || 0;
        var qos = payload.qos || ORDERS_QOS.qos2;
        var url = environment.server + "/api/v2/orders/fetch";
        var requestBody = {
            filters: payload.searchConditions,
            responseParams: {
                fetchAllFields: false,
                requiredFields: payload.requiredFields
            },
            limit: limit,
            offset: offset,
            qos: qos
        };
        return this.httpClient.post("" + url, requestBody).pipe(catchError(this.handleError));
    };
    OrderService.prototype.getPropagationMetricEventsForCaptain = function (userId, eventName, pagination, type) {
        if (type === void 0) { type = ENTITY_TYPES.CAPTAIN; }
        var url = environment.server + "/api/v2/orders/captain/propagation-metric-events";
        var requestBody = {
            filters: {
                captainId: userId,
                eventName: eventName,
            },
            limit: pagination.perPage,
            offset: pagination.perPage * (pagination.pageNo),
            qos: ORDERS_QOS.qos2
        };
        return this.httpClient.post("" + url, requestBody).map(function (res) {
            var count = res.count;
            var events = res.propagationMetricEvents || [];
            return {
                count: count,
                events: events
            };
        }).pipe(catchError(this.handleError));
    };
    OrderService.prototype.getPropagationEventsForCaptain = function (userId, pagination, startDate, endDate) {
        var url = environment.server + "/api/v2/orders/captain/propagation-events";
        var requestBody = {
            filters: {
                captainId: userId,
                startDate: startDate,
                endDate: endDate
            },
            limit: pagination.perPage,
            offset: pagination.perPage * pagination.pageNo,
            responseParams: {
                fetchAllFields: true,
                sortOption: {
                    type: 'timeOfEventCreation',
                    desc: true
                }
            },
        };
        return this.httpClient.post("" + url, requestBody).map(function (res) {
            var count = res.totalRecords;
            var events = res.events || [];
            return {
                count: count,
                events: events
            };
        }).pipe(catchError(this.handleError));
    };
    OrderService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    OrderService.ngInjectableDef = i0.defineInjectable({ factory: function OrderService_Factory() { return new OrderService(i0.inject(i1.HttpClient)); }, token: OrderService, providedIn: "root" });
    return OrderService;
}());
export { OrderService };
