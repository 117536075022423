import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CaptainInvoiceHelperService } from './captain-invoice-details-helper';
import { EntityService } from 'src/app/entity.service';
import { LogService } from '../log-service.service';
@Component({
  selector: 'app-captain-invoice-details',
  templateUrl: './captain-invoice-details.component.html',
  providers: [CaptainInvoiceHelperService, LogService]
})
export class CaptainInvoiceDetailsComponent implements OnInit, OnChanges {

  constructor(
    private captainInvoiceMappingHelper:CaptainInvoiceHelperService,
    private entityService:EntityService,
    private _logService: LogService,
  ){}

  @Input() captainRateCard: any;
  @Input() captainInvoiceDetails: any;
  @Input() orderType: any;
  tooltipContent: String;
  captainRateCardData: any;
  public info: any;
  public data: any = {};
  b2bKeysToShow = ['Total Earnings'];
  roles =  this.accessCookie('Roles').split(',');
  userInfo = this.accessCookie('user');
  userDetails = JSON.parse(this.userInfo);

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && ((changes.captainInvoiceDetails && Object.keys(changes.captainInvoiceDetails.currentValue).length > 0)) ||
      ((changes.captainRateCard && changes.captainRateCard.currentValue && Object.keys(changes.captainRateCard.currentValue).length > 0))) {

      if(changes && changes.captainInvoiceDetails){
        this.captainInvoiceDetails = changes.captainInvoiceDetails.currentValue
      }

      this.captainRateCardData = {
        header: this.captainRateCard.city && this.captainRateCard.city.displayName && this.captainRateCard.serviceName
          && this.captainRateCard.serviceName.name ? 
          (this.captainRateCard.city.displayName + ` - ` + this.captainRateCard.serviceName.name) :
          (this.captainRateCard.city ? this.captainRateCard.city : ''),
        tableValues: {
          'Base Fare': { data: this.captainRateCard.baseFare ? (this.captainRateCard.baseFare.amount 
            ? this.captainRateCard.baseFare.amount : 0) : 0, unit: '₹ ' },
          'Minimum Fare': { data: this.captainRateCard.minimumFare ? (this.captainRateCard.minimumFare.amount 
            ? this.captainRateCard.minimumFare.amount : 0) : 0, unit: '₹ ' },
          'Order Fare': { data: this.captainRateCard.orderFare ? this.captainRateCard.orderFare.amount : 0, unit: '₹ ' },
          'Per Minute': { data: this.captainRateCard.timeFare ? this.captainRateCard.timeFare.pricePerUnit : 0, unit: '₹ ' },
          'Waiting Charges/min': { data: this.captainRateCard.waitingFare ? this.captainRateCard.waitingFare.pricePerUnit:0, unit: '₹ ' },
          'Wait Time Threshold(min)': { data: this.captainRateCard.waitingFare ? this.captainRateCard.waitingFare.threshold : 0, unit: '' },
          'Wait Time Max Threshold(min)': { data: this.captainRateCard.waitingFare ? this.captainRateCard.waitingFare.maxThreshold : 0, unit: '' },
          'FM Threshold (km)': { data: this.captainRateCard.firstMileFare ? this.captainRateCard.firstMileFare.threshold.distance : 0, unit: ''},
          'LM Threshold (km)': { data: this.captainRateCard.lastMileFare ? this.captainRateCard.lastMileFare.threshold.distance : 0, unit: ''},
          'FM incentive/km': { data: this.captainRateCard.firstMileFare ? this.captainRateCard.firstMileFare.pricePerUnit : 0, unit: '₹ '},
          'LM incentive/km': { data: this.captainRateCard.lastMileFare ? this.captainRateCard.lastMileFare.pricePerUnit : 0, unit: '₹ '},
          'Max amount for FM': { data: this.captainRateCard.firstMileFare ? this.captainRateCard.firstMileFare.maxAmount : 0, unit: '₹ '},
          'Min amount for FM': { data: this.captainRateCard.firstMileFare ? this.captainRateCard.firstMileFare.minAmount : 0, unit: '₹ '}
        }
      };

      if (this.captainRateCard.distanceFare) {
        this.captainRateCard.distanceFare.breakUp.forEach((element, index) => {
          if (index == 0) {
            this.captainRateCardData.tableValues["Every Km till " + element.slabDistance + " kms"] = { data: element.pricePerUnit, unit: '₹ ' }
            this.captainRateCardData.tableValues["Slab bonus till " + element.slabDistance + " kms"] = { data: element.slabBonus, unit: '₹ ' }
          }
          else {
            this.captainRateCardData.tableValues["Every km from " + this.captainRateCard.distanceFare.breakUp[index - 1].slabDistance + " to " + element.slabDistance + " kms"] = { data: element.pricePerUnit, unit: '₹ ' }
            this.captainRateCardData.tableValues["Slab bonus from " + this.captainRateCard.distanceFare.breakUp[index - 1].slabDistance + " to " + element.slabDistance + " kms"] = { data: element.slabBonus, unit: '₹ ' }
          }
        });
      }
      if (!(this.captainInvoiceDetails && this.captainInvoiceDetails.earningsBreakup && this.captainInvoiceDetails.earningsBreakup.orderBreakup && this.captainInvoiceDetails.earningsBreakup.orderBreakup.nightFare)){
        delete this.captainRateCardData.tableValues['Night Fare'];
      }
      if (this.captainRateCard && this.captainRateCard.nightFareTooltip) {
        this.captainRateCardData.tableValues['Night Fare'] = { data: this.captainRateCard.nightFareTooltip, unit: '' }
      } else if (this.captainInvoiceDetails && this.captainInvoiceDetails.data && this.captainInvoiceDetails.data.nightFareTooltip) {
        this.captainRateCardData.tableValues['Night Fare'] = { data: this.captainInvoiceDetails.data.nightFareTooltip, unit: '' }
      }
      if (this.captainRateCard.pickupFare && this.captainRateCard.pickupFare.length > 0) {
        this.captainRateCard.pickupFare.forEach((element, index) => {
          if (index == 0) {
            this.captainRateCardData.tableValues["Flat Pick up fare till " + element.kms + " km"] = { data: element.value, unit: '₹ ' }
          }
          else {
            this.captainRateCardData.tableValues["Flat Pick up fare from " + this.captainRateCard.pickupFare[index - 1].kms + " to " + element.kms + " km"] = { data: element.value, unit: '₹ ' }
          }
        });
      }

      this.constructCard(this.captainInvoiceDetails);
    }
  }

  emptyCard(message?) {
    this.info = {
      header: 'Captain earning details',
      dataFetched: false,
      data: null,
      emptyMessage: message || 'No data found ¯\_(ツ)_/¯'
    };
  }

  tooltipData(earningsBreakup) {
      this.tooltipContent = this.captainRateCardData.header 
      + `<div class='dashed-border'>` + this.tableData() + '</div>' 
      + (this.captainRateCardData.rule ? ('*' + this.captainRateCardData.rule) + `<br>` : '');
  }

  tableData() {
    let data = '';
    for (const values in this.captainRateCardData.tableValues) {
      if (values) {
        const key = values;
        const unit = this.captainRateCardData.tableValues[values].unit;
        const content = this.captainRateCardData.tableValues[values].data;
        data += '<div class="left-align">'
                  + key +
                  '<span class="float-right">' + unit + content +
                  '</span>\
                </div>';
      }
    }
    return data;
  }

  handleClick(event: MouseEvent): void {
    this._logService.insertEvent(2, {
      agentId: this.userDetails['_id'],
      email: this.userDetails['emailId'],
      roles: this.roles,
    }, 
    {
      userId: this.captainRateCard.captainId || '',
    }, 
    'profiles-dashboard', 
    this.captainInvoiceDetails
    ).subscribe();
  }

  handleScroll(event: Event): void {
    this._logService.insertEvent(2, {
      agentId: this.userDetails['_id'],
      email: this.userDetails['emailId'],
      roles: this.roles,
    }, 
    {
      userId: this.captainRateCard.captainId || '',
    }, 
    'profiles-dashboard', 
    this.captainInvoiceDetails
    ).subscribe();
  }

  constructCard(captainInvoiceDetails) {
    if (!captainInvoiceDetails || !captainInvoiceDetails.dataFetched || !(captainInvoiceDetails.data || captainInvoiceDetails.earningsBreakup)) {
      this.emptyCard();
      return
    }

    let data = [];
    let earningsBreakup = captainInvoiceDetails.earningsBreakup;
    let existingEarningMappings : any = {};

    if(captainInvoiceDetails.orderAcceptPromiseDetails){
      const orderPromiseDetails = captainInvoiceDetails.orderAcceptPromiseDetails;
      if(Object.keys(orderPromiseDetails).length > 0){
        const orderPromiseObj=[];
        orderPromiseObj.push({headerClass: 'mat-expansion-color' , header: 'Order Earning', value: '₹' + orderPromiseDetails.orderEarning})
        orderPromiseObj.push({headerClass: 'mat-expansion-color' , header: 'Extra Earning', value: '₹' + orderPromiseDetails.extraEarning})
        orderPromiseObj.push({headerClass: 'mat-expansion-color' , header: 'Total Earning', value: '₹' + orderPromiseDetails.totalEarning})
        data.push({headerClass: 'header-card-color', matExpansionPanelContent:orderPromiseObj, isExpansion: true, matExpansionPanelHeader:"Order Accept Screen Details"});
      }
    }

    if(captainInvoiceDetails.asPerNewEarnings){
      if(['link','bike pink','c2c'].includes(captainInvoiceDetails.orderServiceName.toLowerCase())){
        this.captainInvoiceMappingHelper.mapBikeNewEarnings(earningsBreakup, data);
      }
      if(['auto','autopremium'].includes(captainInvoiceDetails.orderServiceName.toLowerCase())){
        this.captainInvoiceMappingHelper.mapAutoEarnings(earningsBreakup, data);
      }
    }else{
        existingEarningMappings = this.captainInvoiceMappingHelper.mapBasedOnOldEarnings(earningsBreakup);
    }

    if (earningsBreakup) {
      this.tooltipData(earningsBreakup);

      
      if (captainInvoiceDetails.tip) {
        existingEarningMappings.tip = captainInvoiceDetails.tip;
      }
      if(!captainInvoiceDetails.asPerNewEarnings){
          if (captainInvoiceDetails.orderStatus !== "dropped") {
            data.push({ header: 'Estimated Total Earnings', headerClass: 'header-card-color', value: '₹ ' + (existingEarningMappings.totalOrderEarning) })
          } else {
            data.push({ header: 'Total Earnings', headerClass: 'header-card-color', value: '₹ ' + (existingEarningMappings.totalOrderEarning) })
          }
          data.push({ header: 'Platform Charges', headerClass: 'header-card-color', value: '₹ ' + existingEarningMappings.platformCharges })
          data.push({ header: 'Order Earnings', headerClass: 'header-card-color', value: '₹ ' + existingEarningMappings.orderEarning })
          if(existingEarningMappings && existingEarningMappings.bidDelta){
            data.push({header: 'Bid Delta', headerClass: 'header-card-color', value: '₹ '+ existingEarningMappings.bidDelta.amount })
          }
          data.push({ header: 'Last Mile (extra)', headerClass: 'header-card-color', value: '₹ ' + existingEarningMappings.lmFare })
          if (existingEarningMappings.pickupFare > 0) {
            data.push({ header: 'Pickup Fare (extra)', headerClass: 'header-card-color', value: '₹ ' + existingEarningMappings.pickupFare })
          } else {
            data.push({ header: 'First Mile (extra)', headerClass: 'header-card-color', value: '₹ ' + existingEarningMappings.fmFare })
          }
          data.push({ header: 'Tip', headerClass: 'header-card-color', value: '₹ ' + existingEarningMappings.tip || '₹ ' + 0 })
          data.push({ header: 'Toll Fare', headerClass: 'header-card-color', value: '₹ ' + existingEarningMappings.tollFare || '₹ ' + 0 })
          if(existingEarningMappings && existingEarningMappings.parkingCharges > 0){
            data.push({header: 'Parking Charges', headerClass: 'header-card-color', value: '₹ '+ existingEarningMappings.parkingCharges })
          }
          data.push({ header: 'Total Surge', headerClass: 'header-card-color', value: '₹ ' + existingEarningMappings.totalSurge })
          data.push({ header: 'Base Fare', headerClass: 'header-card-color', value: '₹ ' + existingEarningMappings.baseFare })
          data.push({ header: 'Distance Fare' + ' (' + captainInvoiceDetails.data.distance + ' km)' , headerClass: 'header-card-color', value: '₹ ' + existingEarningMappings.distanceFare })
          data.push({ header: 'Time Fare'  + ' (' + captainInvoiceDetails.data.totalTime + ' min)' , headerClass: 'header-card-color', value: '₹ ' + existingEarningMappings.timeFare })
          data.push({ header: 'Minimum Fare' , headerClass: 'header-card-color', value: '₹ ' + existingEarningMappings.minimumFare })
          data.push({ header: 'RWT' + ' (' + captainInvoiceDetails.data.waitingTime + ' min)', headerClass: 'header-card-color', value: '₹ ' + existingEarningMappings.waitingFare })
          
          data.push({ header: 'Adjustments', headerClass: 'header-card-color', value: this.captainInvoiceDetails.earningsBreakup.orderBreakup.adjustments})
          data.push({ header: 'Penalty', headerClass: 'header-card-color', value: this.captainInvoiceDetails.earningsBreakup.orderBreakup.penalty})
          data.push({ header: 'TDS Penalty', headerClass: 'header-card-color', value: this.captainInvoiceDetails.earningsBreakup.orderBreakup.tds})
          
          if(this.captainInvoiceDetails && this.captainInvoiceDetails.earningsBreakup && this.captainInvoiceDetails.earningsBreakup.orderBreakup.nightFare){
            data.push({ header: 'Night Fare', headerClass: 'header-card-color', value: '₹ ' +  this.captainInvoiceDetails.earningsBreakup.orderBreakup.nightFare})
          }
          if(earningsBreakup.cancelationEligibility)
          {
            if(!earningsBreakup.cancelationEligibility.cancelationEligibility && earningsBreakup.cancelationEligibility.weeklyLimitExceeded)
            existingEarningMappings.cancelationIncentiveRemarks = "Weekly Limit Exceeded"
            else if(!earningsBreakup.cancelationEligibility.cancelationEligibility && earningsBreakup.cancelationEligibility.dailyLimitExceeded)
            existingEarningMappings.cancelationIncentiveRemarks = "Daily Limit Exceeded"
            else if(!earningsBreakup.cancelationEligibility.cancelationEligibility && !earningsBreakup.cancelationEligibility.cancelationEligibilityCurrentOrder)
            existingEarningMappings.cancelationIncentiveRemarks = earningsBreakup.cancelationEligibility.cancelationEligibilityReason
            data.push({ header: 'Cancellation Incentive', headerClass: 'header-card-color', value: '₹ ' + existingEarningMappings.cancelationIncentive })
            data.push({ header: 'Cancellation Remarks', headerClass: 'header-card-color', value:  existingEarningMappings.cancelationIncentiveRemarks })
          }

          if (this.orderType !== 'delivery') {
            data.push({ header: 'Cash Collected from Customer', headerClass: 'header-card-color', value: '₹ ' + existingEarningMappings.cashCollected })
            if (existingEarningMappings.waitingFare > 0) {
              data.push({ header: 'Waiting Fare' + ' (' + captainInvoiceDetails.data.waitingTime + ' min)', headerClass: 'header-card-color', value: '₹ ' + existingEarningMappings.waitingFare })
            }
          }
          existingEarningMappings.activeUpiId && data.push({ header: 'activeUpiId', headerClass: 'header-card-color', value: existingEarningMappings.activeUpiId })
      }
    }
    this.info = {
      header: 'Captain earning details',
      headerClass: 'color-card-header',
      dataFetched: true,
      customTooltip: this.tooltipContent,
      data: data
    };
  }

  accessCookie(cookieName) {
    const name = cookieName + '=';
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
      if (allCookieArray[i]) {
        const cookieItem = allCookieArray[i].trim();
        if (cookieItem.indexOf(name) === 0) {
          return cookieItem.substring(name.length, cookieItem.length);
        }
      }
    }
    return '';
  }
}