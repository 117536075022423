import { Injectable } from '@angular/core';
import { MyHttpClient } from './http-client.service';
import { environment } from 'src/environments/environment';
const typeMap = {
    1: 'SEARCH_ORDER',
    2: 'CAPTAIN_EARNING_DETAILS_CHECK',
    3: 'CUSTOMER_ADJUSTMENT_ACTION_REFUND',
    4: 'CUSTOMER_ADJUSTMENT_ACTION_CREDIT',
    5: 'CUSTOMER_ADJUSTMENT_ACTION_DEBIT',
    6: 'SEARCH_CAPTAIN_MOBILE'
  };
@Injectable()
export class LogService {
  constructor(
    private http: MyHttpClient) {
  }
  logServiceCall = environment.server + '/api/logService';

    insertEvent(type, agent, user, source, metadata = {}) {
        const payload = {
            type: typeMap[type],
            agent: agent,
            user: user,
            source: source,
            metadata: metadata,
            eventTimeStamp: new Date().getTime()
        };
        return this.http.post(this.logServiceCall, payload);
    }

    insertEventWithType(type, agent, user, source, metadata = {}) {
        const payload = {
            type: type,
            agent: agent,
            user: user,
            source: source,
            metadata: metadata,
            eventTimeStamp: new Date().getTime()
        };
        return this.http.post(this.logServiceCall, payload);
    }
}
