import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
export interface ICommonFilterInput {
  title: string;
  default?: any;
  key: string;
  values?: {};
  onChange?: (value) => void;
} 
let onFilter: any = () => {};

export interface ISearchableDropDownFilterInput extends ICommonFilterInput {
  values: string[];
}

export interface IRadioButtonFilterInput extends ICommonFilterInput {
  values: string[];
}

export interface IRangeSlider extends ICommonFilterInput {
  values: {};
}

export class SearchableDropDownFilterInput {
  type = 'searchable-dropdown';
  title: string;
  default?: string;
  key: string;
  values: string[];
  onChange?: (value) => void;
  constructor(config: ISearchableDropDownFilterInput) {
    Object.assign(this, config);
  }
}

export class SingleSearchableDropdown {
  type = 'single-searchable-dropdown';
  title: string;
  default?: string;
  key: string;
  values: string[];
  onChange?: (value) => void;
  constructor(config: ISearchableDropDownFilterInput) {
    Object.assign(this, config);
  }
}

export class RangeSliderInput {
  type = 'range-slider';
  title: string;
  default?: string;
  key: string;
  values: {};
  value: {};
  onChange?: (value) => void;
  constructor(config: IRangeSlider) {
    Object.assign(this, config);
  }
}

export class TextFilterInput {
  type = 'text-input';
  title: string;
  default?: string;
  key: string;
  constructor(config: ICommonFilterInput) {
    Object.assign(this, config);
  }
}

export class RadioButtonFilterInput {
  type = 'radio-button';
  title: string;
  default?: string;
  key: string;
  values: string[];
  constructor(config: IRadioButtonFilterInput) {
    Object.assign(this, config);
  }
}

export class DateFilterInput {
  type = 'calendar';
  title: string;
  default?: any;
  key: string;
  values: string[];
  constructor(config: ICommonFilterInput) {
    Object.assign(this, config);
  }
  static parseValue(inputDuration) {
    const duration = inputDuration || [];
    let startDuration = null;
    let endDuration = null;
    if (duration instanceof Array) {
      startDuration = duration[0] && new Date(duration[0]);
      endDuration = duration[1] && new Date(duration[1]);
    } else {
      startDuration = duration.start && new Date(duration.start);
      endDuration = duration.end && new Date(duration.end);
    }
    return {
      startDuration,
      endDuration,
    };
  }
  static formatDurationForQuery({
    fromDate,
    toDate,
  }) {
    fromDate = new Date(fromDate);
    toDate = new Date(toDate);
    const startDate = fromDate && fromDate.setDate(fromDate.getDate());
    const endDate = toDate && toDate.setDate(toDate.getDate()) || new Date().getTime();
    return {
      fromDate : new Date(startDate),
      toDate : new Date(endDate)
    };
  }
}


export class QuickFilters {
  type = 'quick-filters';
  title: string;
  default?: any;
  key: string;
  values: string[];
  constructor(config: ICommonFilterInput) {
    Object.assign(this, config);
  }
  static parseValue(inputDuration) {
    const duration = inputDuration || [];
    let startDuration = null;
    let endDuration = null;
    if (duration instanceof Array) {
      startDuration = duration[0] && new Date(duration[0]);
      endDuration = duration[1] && new Date(duration[1]);
    } else {
      startDuration = duration.start && new Date(duration.start);
      endDuration = duration.end && new Date(duration.end);
    }
    return {
      startDuration,
      endDuration,
    };
  }
  static formatDurationForQuery({
    fromDate,
    toDate,
  }) {
    fromDate = new Date(fromDate);
    toDate = new Date(toDate);
    const startDate = fromDate && fromDate.setDate(fromDate.getDate());
    const endDate = toDate && toDate.setDate(toDate.getDate() + 1) || new Date().getTime();
    return {
      fromDate: new Date(startDate),
      toDate: new Date(endDate)
    };
  }
}


@Injectable({
  providedIn: 'root'
})
export class FilterService {
  dataReceived = false;
  filterComponents: any[] = [];
  showFilters = false;
  // appliedFilters: any;
  private _appliedFilters: any;
  set appliedFilters(filters) {
    this._appliedFilters = filters;
    // this.applyFilters(this._appliedFilters);
  }
  get appliedFilters() {
    return this._appliedFilters;
  }
  filterDataChange = new Subject<any>();
  filterValueChange = new Subject<any>();
  filterDefaultValueChange = new Subject<any>();
  onFilterApply?: (filter) => void;
  defineFilters(filterComponents?: ICommonFilterInput[], onFilterApply?: (filter) => void) {
    onFilter = onFilterApply || (() => {});
    this.filterComponents = filterComponents || [];
    this.dataReceived = true;
  }
  filterToggle() {
    this.showFilters = !this.showFilters;
  }
  listen(events) {
    // console.log('listening', events);
  }
  clearFilters() {
    this.dataReceived = false;
  //   setTimeout (() => {
  //     this.dataReceived = true;
  //  }, 200);
  }
  applyFilters(event) {
    this.appliedFilters = event;
    if (onFilter && typeof onFilter === 'function') {
      onFilter(event);
    }
  }
  constructor() {
    this.showFilters = false;
  }
}
